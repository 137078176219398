/* eslint-disable no-console */
const STORE = Symbol('STORE');
const NAME = Symbol('NAME');

class MockStore {
  constructor(name) {
    this[STORE] = new Map();
    this[NAME] = name || 'storage';
  }

  setItem(key, value) {
    // console.log('🙈', this[NAME], key, '=', value);
    this[STORE].set(key, String(value));
  }

  getItem(key) {
    // console.log('🙊', this[NAME], key);
    this[STORE].get(key);
  }

  removeItem(key) {
    // console.log('🙉', this[NAME], key);
    this[STORE].delete(key);
  }
}

export default MockStore;
