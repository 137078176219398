import React, { useCallback } from "react";

function Sorter({ forOrder, order, dir, setOrder, setDir }) {
  const newDir = forOrder === order && dir === "asc" ? "desc" : "asc";
  const dirIcon = newDir === "desc" ? "\u2B06\uFE0F" : "\u2B07\uFE0F";
  const icon = forOrder === order ? dirIcon : "\u2935\uFE0F";

  const click = useCallback(() => {
    setOrder(forOrder);
    setDir(newDir);
  }, [forOrder, newDir, setDir, setOrder]);

  return (
    <button
      type="button"
      className="remove-button-styles"
      title={`Sort ${newDir}`}
      onClick={click}
      style={{ marginLeft: "0.5em" }}
    >
      {icon}
    </button>
  );
}

export default Sorter;
