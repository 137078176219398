import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useFetch, useProfile } from "../utils";

export const SubscriptionContext = createContext({ plans: [], activePlan: null, activeSubscriptions: [] });

export const usePlans = () => useContext(SubscriptionContext).plans;
export const useActivePlan = () => useContext(SubscriptionContext).activePlan;
export const useSubscriptions = () => useContext(SubscriptionContext).activeSubscriptions;

export const SubscriptionProvider = ({ children }) => {
  const authFetch = useFetch();
  const [profile, , invalidateProfile] = useProfile();
  const [plans, setPlans] = useState([]);
  const [activePlan, setActivePlan] = useState(null);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded === false && profile) {
      authFetch("/api/subscriptions")
        .then((r) => r.json())
        .then((response) => {
          setPlans(response.plans.sort((a, b) => a.display_ranking - b.display_ranking));
          setActivePlan(response.plans.filter((p) => p.id === response.active_plan_id)[0]);
          setActiveSubscriptions(response.active_subscriptions);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error("can't fetch subscriptions... uh oh.");
        })
        .then(() => {
          setLoaded(true);
        });
    }
  }, [authFetch, loaded, profile]);

  const value = useMemo(
    () => ({
      plans,
      activePlan,
      activeSubscriptions,
      loaded,
      reload: () => {
        setLoaded(false);
        invalidateProfile();
      },
    }),
    [activePlan, activeSubscriptions, invalidateProfile, loaded, plans]
  );

  return <SubscriptionContext.Provider value={value}>{children}</SubscriptionContext.Provider>;
};

export default {
  usePlans,
  useActivePlan,
  useSubscriptions,
};
