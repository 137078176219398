import React, { useCallback, useState } from "react";
import {
  Page,
  Card,
  TextField,
  Button,
  InlineError,
  InlineStack,
  BlockStack,
} from "@shopify/polaris";
import { useRedirect } from "../utils";

function isValueValid(content) {
  return content && content.length > 2;
}

export default () => {
  const apiKey = process.env.REACT_APP_SHOPIFY_API_KEY;
  const [url, setUrl] = useState("");
  const [isValid, setIsValid] = useState(true);
  const redirect = useRedirect();
  const params = new URLSearchParams(window.location.search);
  const referral = params.get("referral");

  const handleSubmit = useCallback(() => {
    setIsValid(isValueValid(url));
    if (isValueValid(url)) {
      fetch(
        `/api/login?shop=${url}${
          referral ? `&referral=${encodeURIComponent(referral)}` : ""
        }`
      )
        .then((r) => r.json())
        .then(({ url: redirectUrl }) => {
          redirect(redirectUrl);
        });
    }
  }, [redirect, url, referral]);

  const handleUrlChange = useCallback((value) => setUrl(value), []);

  const errorMessage = !isValid ? "Store name is required" : "";

  return (
    <Page>
      <Card>
        <BlockStack gap="400">
          <Button url={`https://www.shopify.com/admin/apps/${apiKey}`} primary>
            Login with Shopify
          </Button>

          <InlineStack gap="200">
            <TextField
              id="shop_url"
              labelHidden
              value={url}
              onChange={handleUrlChange}
              label="Shopify URL"
              type="text"
              suffix=".myshopify.com"
              prefix="https://"
              error={!isValid}
            />
            <Button onClick={handleSubmit}>Install</Button>
          </InlineStack>
          {errorMessage && (
            <InlineError message={errorMessage} fieldID="shop_url" />
          )}
        </BlockStack>
      </Card>
    </Page>
  );
};
