import React, { useCallback, useContext, useReducer, useMemo } from "react";

const MessageDisplayContext = React.createContext(null);
export const useMessageDisplay = () =>
  useContext(MessageDisplayContext).messageDisplay;

export const useUpdateMessageDisplaySuccess = (m) =>
  useContext(MessageDisplayContext).changeMessage.call({ activeSucess: m });

export const useUpdateMessageDisplay = () =>
  useContext(MessageDisplayContext).changeMessage;

const INITAL_SATE = { show: false, className: null, message: null };

const messageDisplayReducer = (last, newValue) => {
  let className = null;
  let show = false;
  let message = null;

  if (newValue.activeSuccess) {
    className = "alert alert-success";
    show = true;
    message = newValue.activeSuccess;
  }
  if (newValue.activeWarning) {
    className = "alert";
    show = true;
    message = newValue.activeWarning;
  }
  if (newValue.activeError) {
    className = "alert alert-error";
    show = true;
    message = newValue.activeError;
  }
  return { className, show, message };
};

export default ({ children }) => {
  const [messageDisplay, dispatchMessageDisplay] = useReducer(
    messageDisplayReducer,
    INITAL_SATE
  );
  const changeMessage = useCallback(
    (d) => {
      dispatchMessageDisplay(d);
    },
    [dispatchMessageDisplay]
  );

  const payload = useMemo(
    () => ({
      changeMessage,
      messageDisplay,
    }),
    [changeMessage, messageDisplay]
  );

  return (
    <MessageDisplayContext.Provider value={payload}>
      {children}
    </MessageDisplayContext.Provider>
  );
};
