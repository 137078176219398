import React, { useCallback } from "react";

import { useFetch } from "../utils";

import {
  useMessageDisplay,
  useUpdateMessageDisplay,
} from "./LegacyMessageContext";

export const useGiftReggieFetch = () => {
  const fetch = useFetch();
  const updateMessage = useUpdateMessageDisplay();

  return useCallback(
    (url, options = {}, ...rest) => {
      updateMessage({}); // reloading content, dismiss all old messages
      return fetch(url, options, ...rest)
        .then((r) => r.json())
        .then((data) => {
          if (data.CODE) {
            updateMessage({ activeError: data.LONG_ERROR });
          } else {
            if (data.ACTIVE_SUCCESS)
              updateMessage({ activeSuccess: data.ACTIVE_SUCCESS });
            if (data.ACTIVE_WARNING)
              updateMessage({ activeWarning: data.ACTIVE_WARNING });
            if (data.ACTIVE_ERROR)
              updateMessage({ activeError: data.ACTIVE_ERROR });
          }
          return data;
        })
        .catch((ex) => {
          console.error(`Error Fetching ${url}`, ex);
        });
    },
    [fetch, updateMessage]
  );
};

export default ({ children }) => {
  const messageDisplay = useMessageDisplay();
  const updateMessageDisplay = useUpdateMessageDisplay();
  const clearMessageDisplay = useCallback(() => {
    updateMessageDisplay({});
  }, [updateMessageDisplay]);

  return (
    <>
      <div id="main-content">
        <div className="container">
          <div className="content">
            <div className="row-fluid">
              <div className="span12">
                <div
                  id="message-display"
                  hidden={!messageDisplay.show}
                  className={messageDisplay.className}
                >
                  <button
                    type="button"
                    className="close"
                    onClick={clearMessageDisplay}
                  >
                    &times;
                  </button>
                  {messageDisplay.message}
                </div>

                {children}
              </div>
            </div>
            <div className="row" />
          </div>
        </div>
      </div>
      <div className="copyright">
        Gift Reggie - &copy; {new Date().getUTCFullYear()}{" "}
        <a href="http://moddapps.com/" target="_blank" rel="noreferrer">
          Modd Apps
        </a>
      </div>
    </>
  );
};
