import React, { useCallback, useState } from "react";
import { Card, TextField, Button } from "@shopify/polaris";

import { useProfile, useFetch, useRedirect } from "../utils";

const UsageChargeWidget = ({ data }) => {
  const authFetch = useFetch();
  const redirect = useRedirect();
  const [usageValue, setUsageValue] = useState();
  const [usageError, setUsageError] = useState();

  const [
    {
      shop: { active_subscription: activeSubscription },
      user,
    },
  ] = useProfile();

  const updateChargeCeiling = useCallback(
    async (newAmount) => {
      console.log("updating charge");
      await authFetch("/api/usage_charge", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount: newAmount }),
      })
        .then((r) => r.json())
        .then((r) => redirect(r.redirect_to))
        .catch((ex) => console.error(ex));
    },
    [authFetch, redirect]
  );
  const csMember =
    user.email.includes("@moddapps.com") ||
    user.email.includes("@eshopadmin.com") ||
    user.watchtower;

  let usageMessage;
  let usageDiff = 0;

  const usageExpected = parseFloat(data.FUTURE_CHARGED_AMOUNT) || 0.0;
  const usageCeiling = parseFloat(activeSubscription.usage_ceiling);

  if (usageCeiling > usageExpected) {
    usageMessage = `This value is currently higher than your expected charge of $${usageExpected.toFixed(
      2
    )}.
            No action is necessary, but if you expect registry growth we recommend you preemptively increase this amount using the options bellow.`;
  } else if (usageCeiling < usageExpected) {
    usageMessage = `This value is currently less than than your expected charge of $${usageExpected.toFixed(
      2
    )}.
            You must use the form below to increase your usage ceiling if you wish to continue registry support.`;
    usageDiff = usageExpected - usageCeiling;
  } else if (usageCeiling === usageExpected) {
    usageMessage = `This value is currently equal to your expected charge of $${usageExpected.toFixed(
      2
    )}.
                No action is strictly necessary, but we highly recommend you preemptively increase this amount using the form bellow to avoid an unexpected overdraft.`;
  } else {
    usageMessage =
      "Use the form below if you would like to increase this value.";
  }
  const minimumReminder = usageDiff
    ? `You must set your usage ceiling to at least ${usageExpected} to account for your future expected charge`
    : "null";
  const handleChange = useCallback(
    (newValue) => {
      setUsageValue(newValue);
      if (newValue >= usageExpected || csMember) {
        setUsageError(null);
      } else {
        setUsageError(minimumReminder);
      }
    },
    [minimumReminder, usageExpected, csMember]
  );

  const submitUsage = useCallback(async () => {
    const usageFloat = parseFloat(usageValue);
    if (usageFloat >= usageExpected || csMember) {
      setUsageError(null);
      updateChargeCeiling(usageFloat);
    } else {
      setUsageError(minimumReminder);
    }
  }, [
    minimumReminder,
    updateChargeCeiling,
    usageExpected,
    usageValue,
    csMember,
  ]);

  return data ? (
    <Card title="Update Usage Charge Ceiling" sectioned>
      <p>
        Your approved usage charge ceiling is currently $
        {usageCeiling.toFixed(2)}.
      </p>
      <p> {usageMessage} </p>
      <p>
        You will <b>not be charged</b> at this time. This will only increase
        your usage charge ceiling.
      </p>
      <TextField
        label="Set Usage Ceiling"
        value={usageValue}
        type="integer"
        onChange={handleChange}
        prefix="$"
        min={csMember ? 0 : usageExpected}
        placeholder={usageExpected}
        helpText={minimumReminder && !usageError}
        autoComplete="off"
        error={usageError}
        align="left"
      />
      <Button onClick={submitUsage}>Submit</Button>
    </Card>
  ) : null;
};

export default UsageChargeWidget;
