import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useFetch, useProfile, Link } from "../utils";
import StyleIsolation from "../components/StyleIsolation";
import Sorter from "./components/Sorter";
import CreatePagination from "./components/CreatePagination";
import LegacyContainer, {
  useGiftReggieFetch,
} from "../components/LegacyContainer";
import LegacyMessageContext from "../components/LegacyMessageContext";

function StockTracking() {
  const authFetch = useGiftReggieFetch();
  const baseFetch = useFetch();
  const [profile] = useProfile();
  const location = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const query = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [criteria, setCriteria] = useState(query.get("criteria") || "handle");
  const [value, setValue] = useState(query.get("value") || "");

  // const [items, setItems] = useState(25);
  const [page, setPage] = useState(1);
  const [dir, setDir] = useState(null);
  const [order, setOrder] = useState(null);

  const updateDir = useCallback((newDir) => {
    setDir(newDir);
    setLoading(true);
  }, []);

  const [
    {
      shop: { shop_origin: shopOrigin },
    },
  ] = useProfile();

  useEffect(() => {
    if (loading) {
      const url = new URL(`/reggie/stock`, window.location);
      url.searchParams.append("page", page || 1);
      url.searchParams.append("criteria", criteria);
      url.searchParams.append("value", value);
      if (dir && order) {
        url.searchParams.append("dir", dir);
        url.searchParams.append("order", order);
      }
      setLoading(false);
      authFetch(url, { headers: { Accept: "application/json" } })
        .then((response) => {
          setData(response);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error("Error getting page data.");
        });
    }
  }, [
    data,
    loading,
    authFetch,
    query,
    order,
    page,
    criteria,
    value,
    dir,
    setLoading,
  ]);

  const updatePage = useCallback((pageNumber) => {
    setPage(pageNumber);
    setLoading(true);
  }, []);

  const submitSearch = useCallback(
    (e) => {
      e.preventDefault();
      const url = new URL(`/reggie/stock`, window.location);
      url.searchParams.append("page", page || 1);
      url.searchParams.append("criteria", criteria);
      url.searchParams.append("value", value);
      if (dir && order) {
        url.searchParams.append("dir", dir);
        url.searchParams.append("order", order);
      }
      authFetch(url, { headers: { Accept: "application/json" } })
        .then((response) => {
          setData(response);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error("Error getting page data.");
        });
    },
    [criteria, value, page, dir, order, authFetch]
  );

  const clearFilters = useCallback(() => {
    authFetch(`/reggie/stock`, { headers: { Accept: "application/json" } })
      .then((response) => {
        setData(response);
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error("Error getting page data.");
      });
    setCriteria("handle");
    setValue("");
  }, [authFetch]);

  const exportCSV = useCallback(() => {
    baseFetch("/reggie/stock", {
      method: "POST",
      body: new URLSearchParams([["action", "export"]]),
    })
      .then((r) => r.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.body.appendChild(document.createElement("a"));
        a.href = url;
        a.download = "stock-export.csv";
        a.click();
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.remove();
        }, 60000);
      });
  }, [baseFetch]);

  return data && profile ? (
    <StyleIsolation>
      <LegacyContainer>
        <h3>Stock Status</h3>
        <hr />
        <p>
          This is a page where you can view the stock of all products in your
          store that are on active registries. This page links directly to the
          front-end of your site, through the product handle, and to the
          back-end of your site, through the product ID number. Every link will
          open in a new window.
        </p>
        <p>
          Clicking on the quantity of active registries will bring up a
          &apos;reverse-stock&apos; look up, which will detail you how many of
          each product are on each registry. Clicking on any of the arrows at
          the top of this table, will let you sort through all your stock.
        </p>
        <p>
          Please note that stock levels will only show if you are already
          tracking inventory in Shopify. Also, please note updates are{" "}
          <b>not instantaneous</b>. If you change the stock level of a product,
          or if an order is made, it may take a few minutes for Gift Reggie to
          pick up on the change.
        </p>

        {!data.CAN_TRACK_STOCK ? (
          <>
            <p className="upgrade-flavour">
              You cannot use the stock tracker on your current plan! Please
              upgrade to the Professional Plan or higher, if you would like to
              use this feature.
            </p>
          </>
        ) : (
          <>
            <div className="row-fluid">
              <div className="span12">
                <form
                  method="get"
                  id="stock-status-form"
                  style={{ margin: 0 }}
                  onSubmit={submitSearch}
                >
                  Show all product variants with a{" "}
                  <select
                    id="stock-criteria"
                    name="criteria"
                    style={{ width: "auto" }}
                    value={criteria}
                    onChange={(e) => setCriteria(e.target.value)}
                  >
                    <option>handle</option>
                    <option>title</option>
                    <option>vendor</option>
                    <option value="product_id">product ID</option>
                    <option value="variant_id">variant ID</option>
                  </select>{" "}
                  like{" "}
                  <input
                    id="stock-value"
                    style={{ width: "128px", marginBottom: 0 }}
                    type="text"
                    name="value"
                    placeholder="anything"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />{" "}
                  <button className="btn" type="submit">
                    Search
                  </button>{" "}
                  <button
                    id="filter-clear"
                    className="btn"
                    type="button"
                    onClick={clearFilters}
                  >
                    Clear
                  </button>
                </form>
              </div>
            </div>
            <div className="row-fluid">
              <div className="span12">
                <table className="table table-striped registry-table">
                  <thead>
                    <tr>
                      <th id="heading-product_handle">
                        Product Handle
                        <Sorter
                          forOrder="product_handle"
                          dir={dir}
                          order={order}
                          setDir={updateDir}
                          setOrder={setOrder}
                        />
                      </th>
                      <th id="heading-title">
                        Product Title
                        <Sorter
                          forOrder="title"
                          dir={dir}
                          order={order}
                          setDir={updateDir}
                          setOrder={setOrder}
                        />
                      </th>
                      <th id="heading-product_id">
                        Product ID
                        <Sorter
                          forOrder="product_id"
                          dir={dir}
                          order={order}
                          setDir={updateDir}
                          setOrder={setOrder}
                        />
                      </th>
                      <th id="heading-variant_id">
                        Variant ID
                        <Sorter
                          forOrder="variant_id"
                          dir={dir}
                          order={order}
                          setDir={updateDir}
                          setOrder={setOrder}
                        />
                      </th>
                      <th id="heading-sku">
                        SKU
                        <Sorter
                          forOrder="sku"
                          dir={dir}
                          order={order}
                          setDir={updateDir}
                          setOrder={setOrder}
                        />
                      </th>
                      <th id="heading-vendor">
                        Vendor
                        <Sorter
                          forOrder="vendor"
                          dir={dir}
                          order={order}
                          setDir={updateDir}
                          setOrder={setOrder}
                        />
                      </th>
                      <th id="heading-price">
                        Price
                        <Sorter
                          forOrder="price"
                          dir={dir}
                          order={order}
                          setDir={updateDir}
                          setOrder={setOrder}
                        />
                      </th>
                      <th id="heading-stock">
                        Stock
                        <Sorter
                          forOrder="stock"
                          dir={dir}
                          order={order}
                          setDir={updateDir}
                          setOrder={setOrder}
                        />
                      </th>
                      <th id="heading-needed">
                        Needed
                        <Sorter
                          forOrder="needed"
                          dir={dir}
                          order={order}
                          setDir={updateDir}
                          setOrder={setOrder}
                        />
                      </th>
                      <th id="heading-differential">
                        Differential
                        <Sorter
                          forOrder="differential"
                          dir={dir}
                          order={order}
                          setDir={updateDir}
                          setOrder={setOrder}
                        />
                      </th>
                      <th id="heading-registries">
                        Active Registries
                        <Sorter
                          forOrder="registries"
                          dir={dir}
                          order={order}
                          setDir={updateDir}
                          setOrder={setOrder}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.VARIANTS ? (
                      <>
                        {data.VARIANTS.map((innerData) => (
                          <tr key={innerData.ID}>
                            <td>
                              <a
                                target="_blank"
                                href={`http://${shopOrigin}/products/${innerData.HANDLE}`}
                                rel="noreferrer"
                              >
                                {innerData.HANDLE}
                              </a>
                            </td>
                            <td>{innerData.TITLE}</td>
                            <td>
                              <a
                                target="_blank"
                                href={`https://${shopOrigin}/admin/products/${innerData.PRODUCT_ID}`}
                                rel="noreferrer"
                              >
                                {innerData.PRODUCT_ID}
                              </a>
                            </td>
                            <td>{innerData.VARIANT_ID}</td>
                            <td>{innerData.SKU}</td>
                            <td>{innerData.VENDOR}</td>
                            <td>{innerData.PRICE}</td>
                            <td>
                              {innerData.HAS_QUANTITY ? (
                                <>{innerData.QUANTITY}</>
                              ) : (
                                <>-</>
                              )}
                            </td>
                            <td>{innerData.NEEDED}</td>
                            <td>
                              {innerData.HAS_QUANTITY ? (
                                <>{innerData.DIFFERENTIAL}</>
                              ) : (
                                <>-</>
                              )}
                            </td>
                            <td>
                              <Link to={`/stock/${innerData.ID}`}>
                                {innerData.REGISTRIES}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan="6">
                          You have no open registries that contain products.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <CreatePagination
              maxPages={data.MAX_PAGES}
              page={page}
              setPage={updatePage}
            />

            <form method="post">
              <div className="row-fluid">
                <div className="span12">
                  <button
                    type="button"
                    onClick={exportCSV}
                    className="btn btn-success"
                    name="action"
                    value="export"
                  >
                    &#x2795;&#xFE0F; Export Stock as CSV
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
      </LegacyContainer>
    </StyleIsolation>
  ) : null;
}

export default () => (
  <LegacyMessageContext>
    <StockTracking />
  </LegacyMessageContext>
);
