/* eslint-disable no-restricted-globals,no-alert */
import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useFetch,
  useProfile,
  useRedirect,
  useFetchSessionToken,
} from "../utils";
// import { useParams } from "react-router-dom";
// import { ResourcePicker } from "@shopify/app-bridge/actions";
import StyleIsolation from "../components/StyleIsolation";
import LegacyContainer from "../components/LegacyContainer";
import LegacyMessageContext from "../components/LegacyMessageContext";
import Sorter from "./components/Sorter";
import CreatePagination from "./components/CreatePagination";

function Wishlist() {
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const fetch = useFetch();
  const fetchSessionToken = useFetchSessionToken();
  const redirect = useRedirect();

  const [
    {
      shop: { shop_origin: shopOrigin },
    },
  ] = useProfile();

  const [items, setItems] = useState(25);
  const [page, setPage] = useState(1);
  const [dir, setDir] = useState(null);
  const [order, setOrder] = useState(null);

  const [pendingAdditions, setPendingAdditions] = useState([]);

  const updateDir = useCallback((newDir) => {
    setDir(newDir);
    setLoaded(false);
  }, []);

  useEffect(() => {
    if (!loaded) {
      const url = new URL(`/reggie/wishlists/${id}`, window.location);
      // if (email) {
      //   url.searchParams.append("email", email);
      // }
      url.searchParams.append("items", items || 50);
      url.searchParams.append("page", page || 1);
      if (dir && order) {
        url.searchParams.append("dir", dir);
        url.searchParams.append("order", order);
      }
      fetch(url, { headers: { Accept: "application/json" } })
        .then((r) => r.json())
        .then((nextData) => {
          setData(nextData);
          setLoaded(true);
        })
        .catch((ex) => {
          console.error("Fetching Dashboard Data", ex);
        });
    }
  }, [dir, fetch, id, items, loaded, order, page]);

  const itemsChange = useCallback(
    (e) => {
      setItems(e.target.value);
      if (Number(items) !== Number(e.target.value)) {
        setPage(1);
      }
      setLoaded(false);
    },
    [items]
  );

  const updatePage = useCallback((pageNumber) => {
    setPage(pageNumber);
    setLoaded(false);
  }, []);

  const [extraVisible, setExtraVisible] = useState(false);
  const toggleExtraVisible = useCallback(
    () => setExtraVisible(!extraVisible),
    [extraVisible]
  );

  const submit = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new URLSearchParams(new FormData(e.target));
      const submitButton = e.nativeEvent.submitter;
      if (submitButton && submitButton.name) {
        formData.append(submitButton.name, submitButton.value || "");
      }
      setData(null);
      fetch(`/reggie/wishlists/${id}`, {
        method: "POST",
        body: formData,
        headers: {
          // Accept: "application/json",
        },
      })
        .then((r) => r.text())
        .then((text) => {
          const errorMatch =
            /displayError\("((?:[^\\"]|\\["bfnrtu])*)"\);/.exec(text);
          const successMatch =
            /displaySuccess\("((?:[^\\"]|\\["bfnrtu])*)"\);/.exec(text);
          const warningMatch =
            /displayWarning\("((?:[^\\"]|\\["bfnrtu])*)"\);/.exec(text);

          if (errorMatch)
            console.error("[GiftReggie] Got a response error", errorMatch[1]);
          if (successMatch)
            console.log("[GiftReggie] Got a success error", successMatch[1]);
          if (warningMatch)
            console.warn("[GiftReggie] Got a warning error", warningMatch[1]);

          setLoaded(false);
          setPendingAdditions([]);
        })
        //  .then((r) => r.json())
        //  .then((nextData) => {
        //    setData(nextData);
        //  })
        .catch((ex) => {
          console.error("Fetching Dashboard Data after Submit", ex);
        });
    },
    [fetch, id]
  );

  const discard = useCallback(() => {
    setLoaded(false);
    setData(null);
  }, []);

  const addRegistryLineNumber = useCallback(async () => {
    if (window.shopify) {
      const selection = await window.shopify.resourcePicker({
        type: "product",
        multiple: true,
      });

      const newLines = selection.flatMap((product) =>
        product.variants.map((variant) => ({
          productId: String(product.id).replace(/^.*?\b(?=\d+$)/, ""),
          variantId: String(variant.id).replace(/^.*?\b(?=\d+$)/, ""),
          title: `${product.title} - ${variant.title}`,
          handle: product.handle,
          sku: variant.sku,
          image: variant.image?.originalSrc ?? product.images[0]?.originalSrc,
          // price: variant.price, // TODO: format this
        }))
      );
      setPendingAdditions([...pendingAdditions, ...newLines]);
    } else {
      setPendingAdditions([
        ...pendingAdditions,
        { title: "Product by ID", handle: "", sku: "", blank: true },
      ]);
    }
  }, [pendingAdditions]);

  const deleteWishlist = useCallback(
    (e) => {
      if (confirm("Are you sure you want to delete this wishlist?")) {
        const formData = new URLSearchParams(new FormData(e.target.form));
        formData.append("action", "close");
        fetch(`/reggie/wishlists/${id}`, {
          method: "POST",
          body: formData,
          headers: {
            // Accept: "application/json",
          },
        }).finally(() => {
          redirect("/wishlists");
        });
      }
    },
    [fetch, id, redirect]
  );

  const print = useCallback(async () => {
    const sessionToken = await fetchSessionToken();
    const url = new URL(`/reggie/wishlists/${id}`, window.location);
    url.searchParams.append("action", "print");
    url.searchParams.append("order", order);
    url.searchParams.append("dir", dir);
    url.searchParams.append("shopify_jwt", sessionToken);
    window.open(url);
  }, [fetchSessionToken, dir, id, order]);

  return data ? (
    <StyleIsolation>
      <LegacyContainer>
        <div className="row-fluid">
          <div className="span12">
            <h3>
              <Link to="/wishlists">Wishlists</Link> &gt; {data.NAME}
            </h3>
            <hr />
          </div>
        </div>

        <div className="row-fluid">
          <div className="span12">
            This wishlist can be viewed in your store by clicking here:{" "}
            <a
              target="_blank"
              href={`https://${shopOrigin}${data.PROXY_CLEAN_URL}/wishlist/${data.ID}`}
              rel="noreferrer"
            >
              https://{shopOrigin}
              {data.PROXY_CLEAN_URL}/wishlist/{data.ID}
            </a>
            .
          </div>
        </div>

        <form
          action="?"
          id="registry-update-form"
          method="post"
          onSubmit={submit}
        >
          <h3>Wishlist Details</h3>
          <div className="row-fluid">
            <div className="span12">
              <table className="table table-striped registry-listing">
                <thead>
                  <tr>
                    <th>ID NUMBER</th>
                    <th>CREATED</th>
                    <th>EMAIL</th>
                    <th>FIRST NAME</th>
                    <th>LAST NAME</th>
                    <th>ITEMS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.ID}</td>
                    <td>{data.CREATED}</td>
                    <td>{data.EMAIL}</td>
                    <td>{data.FIRST_NAME}</td>
                    <td>{data.LAST_NAME}</td>
                    <td>{data.ITEM_COUNT}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row-fluid">
            <div className="span12">
              <button
                type="button"
                onClick={toggleExtraVisible}
                className="button-is-link"
              >
                Show/Hide Details{" "}
                <span style={{ marginTop: "8px" }} className="caret" />
              </button>
            </div>
          </div>
          <div
            id="wishlist-details"
            hidden={!extraVisible}
            className="wishlist-details"
          >
            <div className="row-fluid">
              <div className="span12">
                <h4>Wishlist Extra Information</h4>
              </div>
            </div>
            <div className="row-fluid">
              <div className="span6">Extra Information</div>
              <div className="span6">
                <textarea
                  defaultValue={data.EXTRA}
                  name="extra"
                  style={{ minHeight: "200px" }}
                />
              </div>
            </div>
          </div>
          <div className="row-fluid">
            <div className="span6">
              <h3>Wishlist Listing</h3>
            </div>
            <div className="span6 row-selector">
              Items per page{" "}
              <select
                id="page-selector"
                style={{ width: "80px" }}
                value={items}
                onChange={itemsChange}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
            </div>
          </div>
          <div className="row-fluid">
            <div className="span12">
              <table className="table table-striped registry-listing">
                <tbody>
                  <tr>
                    <th id="heading-title">
                      TITLE
                      <Sorter
                        forOrder="title"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th id="heading-handle">
                      HANDLE
                      <Sorter
                        forOrder="handle"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th id="heading-product-id">
                      PRODUCT ID
                      <Sorter
                        forOrder="product"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th id="heading-variant-id">
                      VARIANT ID
                      <Sorter
                        forOrder="variant"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th id="heading-sku">
                      SKU
                      <Sorter
                        forOrder="sku"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th>IMAGE</th>
                    <th id="heading-price">
                      PRICE
                      <Sorter
                        forOrder="price"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th>DELETE</th>
                  </tr>
                  {data.ITEMS ? (
                    data.ITEMS.map((innerData) => (
                      <tr key={innerData.ID}>
                        <td>
                          <input
                            type="hidden"
                            name="variant-ids"
                            value={innerData.ID}
                          />
                          {innerData.TITLE}
                        </td>
                        <td>
                          <a
                            target="_blank"
                            href={`https://${shopOrigin}/products/${innerData.PRODUCT_HANDLE}`}
                            rel="noreferrer"
                          >
                            {innerData.PRODUCT_HANDLE}
                          </a>
                        </td>
                        <td>
                          <a
                            target="_blank"
                            href={`https://${shopOrigin}/admin/products/${innerData.PRODUCT_ID}`}
                            rel="noreferrer"
                          >
                            {innerData.PRODUCT_ID}
                          </a>
                        </td>
                        <td>{innerData.VARIANT_ID}</td>
                        <td>{innerData.SKU}</td>
                        <td>
                          <img
                            src={String(innerData.IMAGE).replace(
                              /(?=\.[^.]*$)/,
                              "_80x40"
                            )}
                            alt={innerData.TITLE}
                          />
                        </td>
                        <td>{innerData.PRICE}</td>
                        <td>
                          <button
                            type="submit"
                            className="delete-variant"
                            name="action"
                            value={`delete-${innerData.ID}`}
                          >
                            &times;
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="11">
                        There are currently no items part of this wishlist.
                      </td>
                    </tr>
                  )}
                  {pendingAdditions.map(
                    (
                      {
                        productId,
                        variantId,
                        title,
                        handle,
                        sku,
                        image,
                        blank,
                      },
                      index
                    ) => (
                      <tr key={`${variantId}-${index * 1}`}>
                        <td>
                          {!blank && (
                            <>
                              <input
                                type="hidden"
                                name="add-registry-product-id"
                                value={productId}
                              />
                              <input
                                type="hidden"
                                name="add-registry-variant-id"
                                value={variantId}
                              />
                            </>
                          )}
                          {title}
                        </td>
                        <td>
                          {handle && (
                            <a
                              target="_blank"
                              href={`https://${shopOrigin}/products/${handle}`}
                              rel="noreferrer"
                            >
                              {handle}
                            </a>
                          )}
                        </td>
                        <td>
                          {blank ? (
                            <input
                              type="text"
                              name="add-registry-product-id"
                              placeholder="Product ID"
                              style={{ width: "100px" }}
                            />
                          ) : (
                            <a
                              target="_blank"
                              href={`https://${shopOrigin}/admin/products/${productId}`}
                              rel="noreferrer"
                            >
                              {productId}
                            </a>
                          )}
                        </td>
                        <td>
                          {blank ? (
                            <input
                              type="text"
                              name="add-registry-variant-id"
                              placeholder="Variant ID"
                              style={{ width: "100px" }}
                            />
                          ) : (
                            <>{variantId}</>
                          )}
                        </td>
                        <td>{sku}</td>
                        <td>
                          {image && (
                            <img
                              src={String(image).replace(
                                /(?=\.[^.]*$)/,
                                "_80x40"
                              )}
                              alt={title}
                            />
                          )}
                        </td>
                        <td aria-label="empty" />
                        <td aria-label="empty" />
                      </tr>
                    )
                  )}
                  <tr id="add-row">
                    <td className="add-bar" colSpan="8">
                      <button
                        type="button"
                        onClick={addRegistryLineNumber}
                        className="button-is-link"
                      >
                        &#x2795;&#xFE0F; Click here to add another item to this
                        wishlist.
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div id="registry-pagination" style={{ textAlign: "center" }}>
              <CreatePagination
                maxPages={data.MAX_PAGES}
                page={page}
                setPage={updatePage}
              />
            </div>
          </div>
          <button
            type="button"
            className="discard-changes btn btn-danger"
            onClick={discard}
          >
            Discard Changes
          </button>{" "}
          <button
            type="submit"
            className="btn btn-success"
            name="action"
            value="update"
          >
            Save Changes
          </button>
        </form>

        <h3>Wishlist Management</h3>
        <div className="row-fluid">
          <div className="span12">
            <form id="close-form" method="post">
              <button
                type="button"
                className="btn btn-danger"
                name="action"
                value="close"
                onClick={deleteWishlist}
              >
                Delete Wishlist
              </button>{" "}
              <button type="button" className="btn btn-success" onClick={print}>
                View Printer-Friendly Version
              </button>
            </form>
          </div>
        </div>
      </LegacyContainer>
    </StyleIsolation>
  ) : null;
}

export default () => (
  <LegacyMessageContext>
    <Wishlist />
  </LegacyMessageContext>
);
