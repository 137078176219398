/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { useFetch, useProfile, useFetchSessionToken, Link } from "../utils";
import LegacyContainer from "../components/LegacyContainer";
import StyleIsolation from "../components/StyleIsolation";
import CreatePagination from "./components/CreatePagination";
import Sorter from "./components/Sorter";
import LegacyMessageContext from "../components/LegacyMessageContext";

function Registry() {
  const [data, setData] = useState(null);
  const [loadedId, setLoadedId] = useState(false);

  const { id } = useParams();

  const fetch = useFetch();
  const fetchSessionToken = useFetchSessionToken();

  const [
    {
      shop: { shop_origin: shopOrigin },
    },
  ] = useProfile();
  const [items, setItems] = useState(25);
  const [page, setPage] = useState(1);
  const [dir, setDir] = useState(null);
  const [order, setOrder] = useState(null);

  const [pendingAdditions, setPendingAdditions] = useState([]);
  const [pendingMetafields, setPendingMetafields] = useState([]);
  const [destroyedMetafields, setDestroyedMetafields] = useState([]);

  const [addOrderVisible, setAddOrderVisible] = useState(false);
  const [addCustomerVisible, setAddCustomerVisible] = useState(false);
  const [registryDetailsVisible, setRegistryDetailsVisible] = useState(false);

  const [beforeShippingSelector, setBeforeShippingSelector] = useState("");
  const [afterShippingSelector, setAfterShippingSelector] = useState("");

  const beforeShippingDisabled = beforeShippingSelector !== "below";
  const afterShippingDisabled = afterShippingSelector !== "below";

  const showAddOrder = useCallback(() => setAddOrderVisible(true), []);
  const showAddCustomer = useCallback(() => setAddCustomerVisible(true), []);

  const updatePage = useCallback((pageNumber) => {
    setPage(pageNumber);
    setLoadedId(false);
    setAddOrderVisible(false);
    setAddCustomerVisible(false);
    setRegistryDetailsVisible(false);
  }, []);

  const updateItems = useCallback(
    (e) => {
      setItems(Number(e.target.value));
      updatePage(1);
    },
    [updatePage]
  );

  const updateDir = useCallback((newDir) => {
    setDir(newDir);
    setLoadedId(false);
    setAddOrderVisible(false);
    setAddCustomerVisible(false);
    setRegistryDetailsVisible(false);
  }, []);

  useEffect(() => {
    if (id !== loadedId) {
      const url = new URL(`/reggie/registries/${id}`, window.location);
      url.searchParams.append("items", items);
      url.searchParams.append("page", page || 1);
      if (dir && order) {
        url.searchParams.append("dir", dir);
        url.searchParams.append("order", order);
      }

      fetch(url, {
        headers: { Accept: "application/json" },
      })
        .then((r) => r.json())
        .then((nextData) => {
          setData(nextData);
          setLoadedId(id);
          setAddOrderVisible(false);
          setAddCustomerVisible(false);
          setRegistryDetailsVisible(false);
        })
        .catch((ex) => {
          console.error("Fetching Data", ex);
        });
    }
  }, [fetch, loadedId, id, page, dir, order, items]);

  const submit = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);

      // the button that was pressed needs to be added to the form
      const submitButton = e.nativeEvent.submitter;
      if (submitButton && submitButton.name) {
        if (submitButton.name === "action") {
          formData.delete("action");
        }
        formData.append(submitButton.name, submitButton.value || "");
      }

      // if the button that was pressed was the customer delete button, add the customer id
      if (
        submitButton &&
        submitButton.name === "action" &&
        submitButton.value === "delete-customer"
      ) {
        formData.append("id", submitButton.closest("tr").dataset.id);
      }

      const dateFromInputElement = formData.get("event-date");
      try {
        const parsedDate = DateTime.fromISO(dateFromInputElement);
        const perlFriendlyDate = parsedDate.invalid
          ? data?.EVENT_DATE || "" // fall back onto the original passed in data
          : parsedDate.toFormat("MM/dd/yyyy");
        formData.set("event-date", perlFriendlyDate);
      } catch (ex) {
        console.log(
          `[GiftReggie] converting "${dateFromInputElement}" didn't go down so well`,
          ex
        );
      }

      setData(null);
      setPendingAdditions([]);
      setPendingMetafields([]);
      setDestroyedMetafields([]);
      fetch(`/reggie/registries/${id}`, {
        method: "POST",
        body: formData,
      }).finally(() => {
        setLoadedId(false);
        setAddOrderVisible(false);
        setAddCustomerVisible(false);
        setRegistryDetailsVisible(false);
      });
    },
    [data?.EVENT_DATE, fetch, id]
  );

  const addRegistryLineNumber = useCallback(async () => {
    if (window.shopify) {
      const selection = await window.shopify.resourcePicker({
        type: "product",
        multiple: true,
      });

      const newLines = selection.flatMap((product) =>
        product.variants.map((variant) => ({
          productId: String(product.id).replace(/^.*?\b(?=\d+$)/, ""),
          variantId: String(variant.id).replace(/^.*?\b(?=\d+$)/, ""),
          title: `${product.title} - ${variant.title}`,
          handle: product.handle,
          sku: variant.sku,
          image: variant.image?.originalSrc ?? product.images[0]?.originalSrc,
          // price: variant.price, // TODO: format this
        }))
      );
      setPendingAdditions([...pendingAdditions, ...newLines]);
    } else {
      setPendingAdditions([
        ...pendingAdditions,
        { title: "Product by ID", handle: "", sku: "", blank: true },
      ]);
    }
  }, [pendingAdditions]);

  const print = useCallback(async () => {
    const sessionToken = await fetchSessionToken();
    const url = new URL(`/reggie/registries/${id}`, window.location);
    url.searchParams.append("action", "print");
    url.searchParams.append("order", order);
    url.searchParams.append("dir", dir);
    url.searchParams.append("shopify_jwt", sessionToken);
    window.open(url);
  }, [fetchSessionToken, dir, id, order]);

  const toggleRegistryDetailsVisible = useCallback(
    () => setRegistryDetailsVisible(!registryDetailsVisible),
    [registryDetailsVisible]
  );

  const beforeShippingSelectorChange = useCallback(
    (e) => setBeforeShippingSelector(e.target.value),
    []
  );
  const afterShippingSelectorChange = useCallback(
    (e) => setAfterShippingSelector(e.target.value),
    []
  );

  useEffect(() => {
    setBeforeShippingSelector(data?.BEFORE_ADDRESS ? "below" : "above");
    setAfterShippingSelector(data?.AFTER_ADDRESS ? "below" : "above");
  }, [data]);

  const deleteVariant = useCallback((e) => {
    e.target
      .closest("tr")
      .querySelectorAll(".item-wants,.item-has")
      .forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.value = 0;
      });
  }, []);

  const createMetafield = useCallback(
    (e) => {
      const uid = `${+new Date()}-${Math.random()}`;
      setPendingMetafields([
        ...pendingMetafields,
        { uid, itemId: e.target.dataset.itemId },
      ]);
    },
    [pendingMetafields]
  );

  const removeMetafield = useCallback(
    (e) => {
      const buttonDestroyKey = e.target.dataset.destroyKey;
      setPendingMetafields(
        pendingMetafields.filter(({ uid }) => uid !== buttonDestroyKey)
      );
    },
    [pendingMetafields]
  );

  const destroyMetafield = useCallback(
    (e) => {
      const buttonDestroyKey = e.target.dataset.destroyKey;
      setDestroyedMetafields([...destroyedMetafields, buttonDestroyKey]);
    },
    [destroyedMetafields]
  );

  return data ? (
    <StyleIsolation>
      <style>{`
        input[type='text'] {
          margin: 0;
        }

        .registry-details input[type='text'],
        .registry-details select {
          width: 100%;
        }

        .property input[type='text'] {
          display: inline-block;
          box-shadow: none;
          width: 64px;
          height: 12px;
        }
      `}</style>

      <LegacyContainer>
        <div className="row-fluid">
          <div className="span12">
            <h3>
              <Link to="/registries">Registries</Link> &gt; {data.NAME}
            </h3>
            <hr />
          </div>
        </div>

        <div className="row-fluid">
          <div className="span12">
            This registry can be viewed in your store by clicking here:{" "}
            <a
              target="_blank"
              href={`https://${shopOrigin}${data.PROXY_CLEAN_URL}/registry/${data.ID}`}
              rel="noreferrer"
            >
              https://{shopOrigin}
              {data.PROXY_CLEAN_URL}/registry/{data.ID}
            </a>
            .
          </div>
        </div>

        <form id="registry-update-form" onSubmit={submit}>
          <input type="hidden" name="updated" value={data.UPDATED} />
          <h3>Registry Information</h3>
          <div className="row-fluid">
            <div className="span12">
              <table className="table table-striped registry-table">
                <thead>
                  <tr>
                    <th>ID NUMBER</th>
                    <th>LIST NAME</th>
                    <th>LIST TYPE</th>
                    <th>REGISTRANT</th>
                    <th>CO-REGISTRANT</th>
                    <th>DATE CREATED</th>
                    <th>EVENT DATE</th>
                    <th>STATUS</th>
                    <th>ITEMS</th>
                    {data.ALLOW_CONTRIBUTIONS ? <th>CONTRIBUTED</th> : null}
                    <th>VALUE</th>
                    <th aria-hidden />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Link to={`/registries/${data.ID}`}>#{data.ID}</Link>
                    </td>
                    <td>{data.NAME}</td>
                    <td>{data.EVENT_TYPE ? <>{data.EVENT_TYPE}</> : null}</td>
                    <td>{data.REGISTRANT}</td>
                    <td>
                      {data.COREGISTRANT ? <>{data.COREGISTRANT}</> : <>None</>}
                    </td>
                    <td>{data.CREATED}</td>
                    <td>{data.EVENT}</td>
                    <td>{data.STATUS}</td>
                    <td>{data.ITEM_COUNT}</td>
                    {data.CONTRIBUTIONS ? (
                      <td>
                        <Link to={`/registries/${data.ID}/contributions`}>
                          {data.CONTRIBUTED}
                        </Link>
                      </td>
                    ) : null}
                    <td>{data.VALUE}</td>
                    <td aria-hidden />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row-fluid">
            <div className="span12">
              <button
                type="button"
                className="button-is-link"
                id="open-registry-details"
                onClick={toggleRegistryDetailsVisible}
              >
                {registryDetailsVisible ? "Hide" : "Show"} Details
              </button>
            </div>
          </div>

          <div
            id="registry-details"
            className="registry-details"
            hidden={!registryDetailsVisible}
          >
            <div className="row-fluid">
              <div className="span12">
                <h4>Registry General Information</h4>
              </div>
            </div>
            <div className="row-fluid">
              <div className="span6">Registry Title</div>
              <div className="span6">
                <input
                  name="registry-title"
                  type="text"
                  defaultValue={data.NAME}
                />
              </div>
            </div>
            <div className="row-fluid">
              <div className="span6">
                {data.REGISTRY_IMAGE ? (
                  <a
                    target="_blank"
                    href={data.REGISTRY_IMAGE}
                    rel="noreferrer"
                  >
                    Registry Image
                  </a>
                ) : (
                  "Registry Image"
                )}
              </div>
              <div className="span6">
                <input type="file" name="registry-image" />
                {data.REGISTRY_IMAGE ? (
                  <>
                    <button name="action" type="submit" value="remove-image">
                      Delete Image
                    </button>
                  </>
                ) : null}
              </div>
            </div>
            <div className="row-fluid">
              <div className="span6">Registry Public Description</div>
              <div className="span6">
                <textarea
                  style={{ display: "block", width: "100%" }}
                  name="registry-description"
                  defaultValue={data.DESCRIPTION}
                />
              </div>
            </div>
            <div className="row-fluid">
              <div className="span6">Event Type</div>
              <div className="span6">
                <select
                  id="event-type"
                  name="event-type"
                  defaultValue={data.EVENT_TYPE}
                >
                  {data.EVENT_TYPES ? (
                    data.EVENT_TYPES.map((data) => (
                      <option key={data.NAME}>{data.NAME}</option>
                    ))
                  ) : (
                    <option>N/A</option>
                  )}
                </select>
              </div>
            </div>
            <div className="row-fluid">
              <div className="span6">Event Date</div>
              <div className="span6">
                <input
                  name="event-date"
                  className="datepicker"
                  type="date"
                  required
                  min="2000-01-01"
                  defaultValue={
                    DateTime.fromFormat(
                      data.EVENT_DATE,
                      "MM/dd/yyyy"
                    ).toISODate() || ""
                  }
                />
              </div>
            </div>
            <div className="row-fluid">
              <div className="span6">How did you hear about us?</div>
              <div className="span6">
                <select
                  id="about-type"
                  name="about-type"
                  defaultValue={data.ABOUT_TYPE}
                >
                  {data.ABOUT_REQUIRED ? null : <option>N/A</option>}
                  {data.ABOUT_TYPES.map((data) => (
                    <option>{data.NAME}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row-fluid">
              <div className="span6">Email registry managers? </div>
              <div className="span6">
                <input
                  type="checkbox"
                  id="email-managers"
                  name="email-managers"
                  defaultChecked={!!data.EMAIL_MANAGERS}
                />
              </div>
            </div>

            {data.ALLOW_PASSWORDS ? (
              <>
                <div className="row-fluid">
                  <div className="span6">
                    Registry Password{" "}
                    {data.HAS_PASSWORD ? <>(Active)</> : <>(Inactive)</>}
                  </div>
                  {data.HAS_PASSWORD ? (
                    <>
                      <div className="span4">
                        <input type="text" name="registry-password" />
                      </div>
                      <div className="span2">
                        <button
                          type="submit"
                          name="action"
                          className="btn btn-danger"
                          value="remove-password"
                        >
                          Remove Password
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="span6">
                      <input type="text" name="registry-password" />
                    </div>
                  )}
                </div>
              </>
            ) : null}
            <div className="row-fluid">
              <div className="span6">Registry Extra Information</div>
              <div className="span6">
                <textarea
                  name="extra"
                  style={{ width: "100%" }}
                  defaultValue={data.EXTRA}
                />
              </div>
            </div>
            <hr />
            <div className="row-fluid">
              <div className="span12">
                <h4>Registry Contact Information</h4>
              </div>
            </div>
            <div className="row-fluid">
              <div className="span6">
                <div className="row-fluid">
                  <div className="span6">Registrant Title</div>
                  <div className="span6">
                    <input
                      name="registrant-title"
                      type="text"
                      maxLength="16"
                      defaultValue={data.REGISTRANT_TITLE}
                    />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span6">Registrant First Name</div>
                  <div className="span6">
                    <input
                      name="registrant-first"
                      type="text"
                      defaultValue={data.REGISTRANT_FIRST}
                    />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span6">Registrant Last Name</div>
                  <div className="span6">
                    <input
                      name="registrant-last"
                      type="text"
                      defaultValue={data.REGISTRANT_LAST}
                    />
                  </div>
                </div>
              </div>
              <div className="span6">
                <div className="row-fluid">
                  <div className="span6">Co-Registrant Title</div>
                  <div className="span6">
                    <input
                      name="coregistrant-title"
                      type="text"
                      maxLength="16"
                      defaultValue={data.COREGISTRANT_TITLE}
                    />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span6">Co-Registrant First Name</div>
                  <div className="span6">
                    <input
                      name="coregistrant-first"
                      type="text"
                      defaultValue={data.COREGISTRANT_FIRST}
                    />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span6">Co-Registrant Last Name</div>
                  <div className="span6">
                    <input
                      name="coregistrant-last"
                      type="text"
                      defaultValue={data.COREGISTRANT_LAST}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row-fluid">
              <div className="span6">
                <div className="row-fluid">
                  <div className="span6">Address</div>
                  <div className="span6">
                    <input
                      name="contact-address"
                      type="text"
                      defaultValue={data.CONTACT_ADDRESS}
                    />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span6">City</div>
                  <div className="span6">
                    <input
                      name="contact-city"
                      type="text"
                      defaultValue={data.CONTACT_CITY}
                    />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span6">State/Province</div>
                  <div className="span6">
                    <input
                      name="contact-province"
                      type="text"
                      defaultValue={data.CONTACT_PROVINCE}
                    />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span6">Country</div>
                  <div className="span6">
                    <input
                      name="contact-country"
                      type="text"
                      defaultValue={data.CONTACT_COUNTRY}
                    />
                  </div>
                </div>
              </div>
              <div className="span6">
                <div className="row-fluid">
                  <div className="span6">Zip/Postal Code</div>
                  <div className="span6">
                    <input
                      name="contact-postal"
                      type="text"
                      defaultValue={data.CONTACT_POSTAL}
                    />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span6">Daytime Telephone</div>
                  <div className="span6">
                    <input
                      name="contact-daytime"
                      type="text"
                      defaultValue={data.CONTACT_DAYTIME}
                    />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span6">Evening Telephone</div>
                  <div className="span6">
                    <input
                      name="contact-evening"
                      type="text"
                      defaultValue={data.CONTACT_EVENING}
                    />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span6">Email</div>
                  <div className="span6">
                    <input
                      name="contact-email"
                      type="text"
                      defaultValue={data.CONTACT_EMAIL}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {data.ALLOW_PICKUP ? (
              <>
                <div className="row-fluid">
                  <div className="span12">
                    <select
                      name="store-pickup"
                      defaultValue={data.IN_STORE_PICKUP ? 1 : 0}
                    >
                      <option value="0">
                        I&apos;d like to have my registry items shipped.
                      </option>
                      <option value="1">
                        I&apos;d like to pick up my registry items in store.
                      </option>
                    </select>
                  </div>
                </div>
                <hr />
              </>
            ) : null}
            <div className="row-fluid">
              <div className="span6">
                <div className="row-fluid">
                  <div className="span6">
                    Before event, send gifts to the address
                  </div>
                  <div className="span6">
                    <select
                      id="before-shipping-selector"
                      value={beforeShippingSelector}
                      onChange={beforeShippingSelectorChange}
                    >
                      <option>above</option>
                      <option>below</option>
                    </select>
                  </div>
                </div>
                <div className="before-shipping">
                  <div className="row-fluid">
                    <div className="span6">Address</div>
                    <div className="span6">
                      <input
                        name="before-address"
                        type="text"
                        disabled={beforeShippingDisabled}
                        defaultValue={data.BEFORE_ADDRESS}
                      />
                    </div>
                  </div>
                  <div className="row-fluid">
                    <div className="span6">City</div>
                    <div className="span6">
                      <input
                        name="before-city"
                        type="text"
                        disabled={beforeShippingDisabled}
                        defaultValue={data.BEFORE_CITY}
                      />
                    </div>
                  </div>
                  <div className="row-fluid">
                    <div className="span6">State/Province</div>
                    <div className="span6">
                      <input
                        name="before-province"
                        type="text"
                        disabled={beforeShippingDisabled}
                        defaultValue={data.BEFORE_PROVINCE}
                      />
                    </div>
                  </div>
                  <div className="row-fluid">
                    <div className="span6">Country</div>
                    <div className="span6">
                      <input
                        name="before-country"
                        type="text"
                        disabled={beforeShippingDisabled}
                        defaultValue={data.BEFORE_COUNTRY}
                      />
                    </div>
                  </div>
                  <div className="row-fluid">
                    <div className="span6">Zip/Postal Code</div>
                    <div className="span6">
                      <input
                        name="before-postal"
                        type="text"
                        disabled={beforeShippingDisabled}
                        defaultValue={data.BEFORE_POSTAL}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="span6">
                <div className="row-fluid">
                  <div className="span6">
                    After event, send gifts to the address
                  </div>
                  <div className="span6">
                    <select
                      id="after-shipping-selector"
                      value={afterShippingSelector}
                      onChange={afterShippingSelectorChange}
                    >
                      <option>above</option>
                      <option>below</option>
                    </select>
                  </div>
                </div>
                <div className="after-shipping">
                  <div className="row-fluid">
                    <div className="span6">Address</div>
                    <div className="span6">
                      <input
                        name="after-address"
                        type="text"
                        disabled={afterShippingDisabled}
                        defaultValue={data.AFTER_ADDRESS}
                      />
                    </div>
                  </div>
                  <div className="row-fluid">
                    <div className="span6">City</div>
                    <div className="span6">
                      <input
                        name="after-city"
                        type="text"
                        disabled={afterShippingDisabled}
                        defaultValue={data.AFTER_CITY}
                      />
                    </div>
                  </div>
                  <div className="row-fluid">
                    <div className="span6">State/Province</div>
                    <div className="span6">
                      <input
                        name="after-province"
                        type="text"
                        disabled={afterShippingDisabled}
                        defaultValue={data.AFTER_PROVINCE}
                      />
                    </div>
                  </div>
                  <div className="row-fluid">
                    <div className="span6">Country</div>
                    <div className="span6">
                      <input
                        name="after-country"
                        type="text"
                        disabled={afterShippingDisabled}
                        defaultValue={data.AFTER_COUNTRY}
                      />
                    </div>
                  </div>
                  <div className="row-fluid">
                    <div className="span6">Zip/Postal Code</div>
                    <div className="span6">
                      <input
                        name="after-postal"
                        type="text"
                        disabled={afterShippingDisabled}
                        defaultValue={data.AFTER_POSTAL}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="submit"
              style={{ marginTop: "20px", marginBottom: "10px" }}
              id="save-details-changes"
              className="btn btn-success"
            >
              Save Changes
            </button>
          </div>
          <hr />
          <div className="row-fluid">
            <div className="span6">
              <h3>Registry Listing</h3>
            </div>
            <div className="span6 row-selector">
              Items per page
              <select
                id="page-selector"
                style={{ width: "80px" }}
                value={String(items)}
                onChange={updateItems}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
            </div>
          </div>
          <input type="hidden" name="action" value="update" />
          <div className="row-fluid">
            <div className="span12">
              <table className="table registry-listing">
                {/* className table-striped was removed for newLine highlights */}
                <thead>
                  <tr>
                    <th id="heading-line">
                      LINE #{" "}
                      <Sorter
                        forOrder="line"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th id="heading-title">
                      TITLE{" "}
                      <Sorter
                        forOrder="title"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th id="heading-handle">
                      HANDLE{" "}
                      <Sorter
                        forOrder="handle"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th id="heading-product-id">
                      PRODUCT ID{" "}
                      <Sorter
                        forOrder="product"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th id="heading-variant-id">
                      VARIANT ID{" "}
                      <Sorter
                        forOrder="variant"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th id="heading-sku">
                      SKU{" "}
                      <Sorter
                        forOrder="sku"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th>IMAGE</th>
                    <th id="heading-price">
                      PRICE{" "}
                      <Sorter
                        forOrder="price"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th id="heading-wants">
                      WANTS{" "}
                      <Sorter
                        forOrder="wants"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th id="heading-has">
                      HAS{" "}
                      <Sorter
                        forOrder="has"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th id="heading-needs">
                      NEEDS{" "}
                      <Sorter
                        forOrder="needs"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th>DELETE</th>
                  </tr>
                </thead>
                <tbody>
                  {data.ITEMS ? (
                    data.ITEMS.map((itemData) => (
                      <tr data-id={itemData.ID} key={itemData.ID}>
                        <td>
                          <input
                            type="hidden"
                            name="variant-ids"
                            value={itemData.ID}
                          />
                          <input
                            aria-label="Position"
                            type="text"
                            name={`position-${itemData.ID}`}
                            defaultValue={itemData.POSITION}
                          />
                        </td>
                        <td>
                          {itemData.TITLE}
                          {data.ALLOW_PROPERTIES
                            ? itemData.PROPERTIES.map((data) => (
                                <div className="property" key={data.NAME}>
                                  {data.NAME} -{" "}
                                  {typeof data.VALUE === "string"
                                    ? data.VALUE
                                    : JSON.stringify(data.VALUE)}
                                </div>
                              ))
                            : null}
                          {itemData.METAFIELDS.map((metafieldData) =>
                            destroyedMetafields.includes(
                              `${btoa(itemData.ID)}.${btoa(metafieldData.NAME)}`
                            ) ? null : (
                              <div
                                className="property"
                                key={metafieldData.NAME}
                              >
                                <input
                                  type="text"
                                  name={`metafield-name-${itemData.ID}`}
                                  defaultValue={metafieldData.NAME}
                                />{" "}
                                <input
                                  type="text"
                                  name={`metafield-value-${itemData.ID}`}
                                  defaultValue={metafieldData.VALUE}
                                />{" "}
                                <button
                                  type="button"
                                  onClick={destroyMetafield}
                                  data-destroy-key={`${btoa(
                                    itemData.ID
                                  )}.${btoa(metafieldData.NAME)}`}
                                >
                                  &times;
                                </button>
                              </div>
                            )
                          )}

                          {pendingMetafields.map(({ uid, itemId }) =>
                            itemId === String(itemData.ID) ? (
                              <div className="property" key={uid}>
                                <input
                                  type="text"
                                  name={`metafield-name-${itemData.ID}`}
                                />{" "}
                                <input
                                  type="text"
                                  name={`metafield-value-${itemData.ID}`}
                                />{" "}
                                <button
                                  type="button"
                                  onClick={removeMetafield}
                                  data-destroy-key={uid}
                                >
                                  &times;
                                </button>
                              </div>
                            ) : null
                          )}

                          {data.ALLOW_METAFIELDS ? (
                            <div className="add-metafield">
                              <button
                                type="button"
                                onClick={createMetafield}
                                data-item-id={String(itemData.ID)}
                                className="button-is-link"
                              >
                                Click here to add a metafield to this item.
                              </button>
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <a
                            target="_blank"
                            href={`https://${shopOrigin}/products/${itemData.PRODUCT_HANDLE}`}
                            rel="noreferrer"
                          >
                            {itemData.PRODUCT_HANDLE}
                          </a>
                        </td>
                        <td>
                          <a
                            target="_blank"
                            href={`https://${shopOrigin}/admin/products/${itemData.PRODUCT_ID}`}
                            rel="noreferrer"
                          >
                            {itemData.PRODUCT_ID}
                          </a>
                        </td>
                        <td>
                          <a
                            target="_blank"
                            href={`https://${shopOrigin}/admin/products/${itemData.PRODUCT_ID}/variants/${itemData.VARIANT_ID}`}
                            rel="noreferrer"
                          >
                            {itemData.VARIANT_ID}
                          </a>
                        </td>
                        <td>{itemData.SKU}</td>
                        <td>
                          {itemData.IMAGE && (
                            <img
                              src={String(itemData.IMAGE).replace(
                                /(?=\.[^.]*$)/,
                                "_80x40"
                              )}
                              alt={itemData.TITLE}
                            />
                          )}
                        </td>
                        <td>{itemData.PRICE}</td>
                        <td>
                          <input
                            type="text"
                            aria-label="Wants"
                            className="item-wants"
                            name={`wants-${itemData.ID}`}
                            defaultValue={itemData.WANTS}
                            maxLength="5"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            aria-label="Has"
                            className="item-has"
                            name={`has-${itemData.ID}`}
                            defaultValue={itemData.HAS}
                            maxLength="5"
                          />
                        </td>
                        <td>{itemData.STILL_NEEDS}</td>
                        <td>
                          <button
                            type="submit"
                            className="delete-variant"
                            onClick={deleteVariant}
                          >
                            &times;
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12">
                        There are currently no items part of this registry.
                      </td>
                    </tr>
                  )}

                  {pendingAdditions.map(
                    (
                      {
                        productId,
                        variantId,
                        title,
                        handle,
                        sku,
                        image,
                        blank,
                      },
                      index
                    ) => (
                      <tr
                        key={`pending-${variantId}-${index * 1}`}
                        style={{ backgroundColor: "#dee" }}
                      >
                        <td
                          id="add-registry-line-number"
                          aria-label="New Row"
                        />
                        <td id="add-registry-product-title">{title}</td>
                        <td>{handle}</td>
                        <td className="add-registry-product-id">
                          {blank ? (
                            <input
                              type="text"
                              name="add-registry-product-id"
                              placeholder="Product ID"
                            />
                          ) : (
                            <>
                              <input
                                type="hidden"
                                name="add-registry-product-id"
                                value={productId}
                              />
                              {productId}
                            </>
                          )}
                        </td>
                        <td className="add-registry-variant-id">
                          {blank ? (
                            <input
                              type="text"
                              name="add-registry-variant-id"
                              placeholder="Variant ID"
                            />
                          ) : (
                            <>
                              <input
                                type="hidden"
                                name="add-registry-variant-id"
                                value={variantId}
                              />
                              {variantId}
                            </>
                          )}
                        </td>
                        <td className="add-registry-sku">{sku}</td>
                        <td className="add-registry-product-image">
                          {image && (
                            <img
                              src={String(image).replace(
                                /(?=\.[^.]*$)/,
                                "_80x40"
                              )}
                              alt={title}
                            />
                          )}
                        </td>
                        <td
                          className="add-registry-variant-price"
                          aria-label="Unknown Price"
                        >
                          &nbsp;
                        </td>
                        <td className="add-registry-variant-wants">
                          <input
                            name="add-registry-variant-wants"
                            aria-label="Wants"
                            type="text"
                            defaultValue="1"
                          />
                        </td>
                        <td className="add-registry-variant-has">
                          <input
                            name="add-registry-variant-has"
                            aria-label="Has"
                            type="text"
                            defaultValue="0"
                          />
                        </td>
                        <td aria-label="empty" />
                        <td>
                          <button
                            type="submit"
                            className="delete-variant"
                            onClick={() =>
                              setPendingAdditions([
                                ...pendingAdditions.slice(0, index),
                                ...pendingAdditions.slice(index + 1),
                              ])
                            }
                          >
                            &times;
                          </button>
                        </td>
                      </tr>
                    )
                  )}

                  {pendingAdditions.length > 0 ? (
                    <tr style={{ backgroundColor: "#dee" }}>
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td
                        className="add-registry-variant-still-needs"
                        style={{ minWidth: "55px" }}
                      >
                        <button
                          type="submit"
                          name="action"
                          value="update"
                          className="btn btn-success"
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
                <tfoot>
                  <tr id="add-row">
                    <td className="add-bar" colSpan="12">
                      <button
                        type="button"
                        onClick={addRegistryLineNumber}
                        className="button-is-link"
                      >
                        &#x2795;&#xFE0F; Click here to add another item to this
                        registry.
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div id="registry-pagination" style={{ textAlign: "center" }}>
              <CreatePagination
                maxPages={data.MAX_PAGES}
                page={page}
                setPage={updatePage}
              />
            </div>
          </div>
          <hr />
          <h3>Registry Notes</h3>
          <p>
            This field is for internal notes about this registry. Customers
            cannot view them.
          </p>
          <div className="row-fluid">
            <div className="row-fluid">
              <div className="span12">
                <textarea
                  name="admin-description"
                  style={{ width: "100%", minHeight: "100px" }}
                  defaultValue={data.ADMIN_DESCRIPTION}
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            id="save-listing-changes"
            className="btn btn-success"
          >
            Save Changes
          </button>
        </form>
        <hr />
        <div className="row-fluid">
          <div className="span12">
            <h3>Registry Purchase History</h3>
            <p>
              Here is a listing of all orders associated to this registry. Any
              order purchased in your store through this registry&apos;s page,
              will <b>automatically</b> be marked as being for this registry.
            </p>
            <p>
              The orders here will automatically adjust the &quot;has&quot;
              quantity in the registry above. If you like, you can also{" "}
              <b>manually</b> associate orders with this registry, in case a
              user has ordered something without visiting the registry&apos;s
              page first. Please note, that manually associating orders with
              registries is irreversible; they cannot be unassociated.
            </p>

            <form method="post" onSubmit={submit}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ORDER LINE #</th>
                    <th>ORDER ID</th>
                    <th>CREATED</th>
                    <th>CANCELLED</th>
                    <th>EMAIL</th>
                    <th>PURCHASER</th>
                    <th>NAME</th>
                    <th>PRODUCT ID</th>
                    <th>VARIANT ID</th>
                    <th>SKU</th>
                    <th>QUANTITY</th>
                    <th>LINE PRICE</th>
                    <th>ASSOCIATION</th>
                  </tr>
                </thead>
                <tbody>
                  {data.ORDERS ? (
                    data.ORDERS.map((data) => (
                      <tr key={data.__counter__}>
                        <td>#{data.__counter__}</td>
                        <td>
                          <a
                            href={`https://${shopOrigin}/admin/orders/${data.ORDER_ID}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {data.ORDER_NAME}
                          </a>
                        </td>
                        <td>{data.CREATED}</td>
                        <td>
                          {data.CANCELLED ? <>{data.CANCELLED}</> : <>N/A</>}
                        </td>
                        <td>{data.ORDER_EMAIL}</td>
                        <td>{data.ORDER_CUSTOMER}</td>
                        <td>{data.TITLE}</td>
                        <td>
                          <a
                            target="_blank"
                            href={`https://${shopOrigin}/admin/products/${data.PRODUCT_ID}`}
                            rel="noreferrer"
                          >
                            {data.PRODUCT_ID}
                          </a>
                        </td>
                        <td>{data.VARIANT_ID}</td>
                        <td>{data.SKU}</td>
                        <td>{data.QUANTITY}</td>
                        <td>{data.PRICE}</td>
                        <td>{data.ASSOCIATION}</td>
                      </tr>
                    ))
                  ) : (
                    <tr className="order-empty-row" hidden={addOrderVisible}>
                      <td colSpan="13">
                        There are currently no items that have been purchased
                        from this registry.
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr className="add-order-pre-row" hidden={addOrderVisible}>
                    <td colSpan="13">
                      <button
                        id="add-order"
                        onClick={showAddOrder}
                        type="button"
                        className="button-is-link"
                      >
                        &#x2795;&#xFE0F; Click here to <b>manually</b> associate
                        an order to this registry.
                      </button>
                    </td>
                  </tr>
                  <tr className="add-order-post-row" hidden={!addOrderVisible}>
                    <td aria-hidden>&nbsp;</td>
                    <td>
                      <input
                        type="text"
                        aria-label="Order Name"
                        placeholder="Order #"
                        name="order-name"
                      />
                    </td>
                    <td colSpan="10" aria-hidden>
                      &nbsp;
                    </td>
                    <td>
                      <button
                        name="action"
                        type="submit"
                        value="create-order"
                        className="btn btn-success"
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </form>
          </div>
        </div>
        <div className="row-fluid">
          <div className="span12">
            <h3>Registry Users</h3>
            <p>
              Here, you can add or create users to manage this registry. All you
              need is an email address. If you add user here, Gift Reggie will
              search your store to see if that user exists. If it does not, we
              create it, and send them your store&apos;s default invitation to
              finalize their account and create their password; and then attach
              them to the registry.
            </p>
            <p>
              If they already exist on your store, they are simply added to the
              registry; taking the first and last name that exists on Shopify.
              No emails will be sent.
            </p>
            <form method="post" onSubmit={submit}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>EMAIL</th>
                    <th>FIRST NAME</th>
                    <th>LAST NAME</th>
                    <th>ROLE</th>
                    <th>DELETE</th>
                  </tr>
                </thead>
                <tbody>
                  {data.USERS ? (
                    data.USERS.map((data) => (
                      <tr data-id={data.ID} key={data.ID}>
                        <td>
                          <a
                            target="_blank"
                            href={`https://${data.SHOP_URL}/admin/customers/${data.SHOPIFY_ID}`}
                            rel="noreferrer"
                          >
                            {data.SHOPIFY_ID}
                          </a>
                        </td>
                        <td>{data.EMAIL}</td>
                        <td>{data.FIRST_NAME}</td>
                        <td>{data.LAST_NAME}</td>
                        <td>{data.ROLE}</td>
                        <td>
                          <button
                            className="delete-button"
                            name="action"
                            type="submit"
                            value="delete-customer"
                          >
                            &times;
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr
                      className="customer-empty-row"
                      hidden={addCustomerVisible}
                    >
                      <td colSpan="6">
                        There are no users associated with this registry.
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr
                    className="add-customer-pre-row"
                    hidden={addCustomerVisible}
                  >
                    <td colSpan="6">
                      <button
                        id="add-customer"
                        onClick={showAddCustomer}
                        type="button"
                        className="button-is-link"
                      >
                        &#x2795;&#xFE0F; Click here to add a customer to this
                        registry.
                      </button>
                    </td>
                  </tr>
                  <tr
                    className="add-customer-post-row"
                    hidden={!addCustomerVisible}
                  >
                    <td>?</td>
                    <td>
                      <input
                        type="text"
                        name="email"
                        aria-label="Email Address"
                        placeholder="Email Address"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="first-name"
                        aria-label="First Name (Optional)"
                        placeholder="First Name (Optional)"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="last-name"
                        aria-label="Last Name (Optional)"
                        placeholder="Last Name (Optional)"
                      />
                    </td>
                    <td>
                      <select name="role">
                        <option value="manager">Manager</option>
                        <option value="invitee">Invitee</option>
                      </select>
                    </td>
                    <td>
                      <button
                        name="action"
                        type="submit"
                        value="create-customer"
                        className="btn btn-success"
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </form>
          </div>
          <h3>Registry Management</h3>
          <div className="row-fluid">
            <div className="span12">
              <p>
                Under this section, you can perform several actions on the
                registry, including settings user owners, opening & closing it,
                as well as exporting it in a CSV (comma separated value file).
                In the future we will also be handling the importation of
                registries. If you&apos;d like to have your registry listing
                imported now, don&apos;t hesitate to contact us at
                gift-reggie@eshopadmin.com, and we&apos;ll be happy to manually
                upload your registry listing into our database.
              </p>
              <form method="post" onSubmit={submit}>
                {data.OPEN ? (
                  <button
                    name="action"
                    type="submit"
                    className="btn btn-warning"
                    value="close"
                  >
                    Close Registry
                  </button>
                ) : null}
                {data.CLOSED ? (
                  <button
                    name="action"
                    type="submit"
                    className="btn btn-success"
                    value="open"
                  >
                    Re-Open Registry
                  </button>
                ) : null}{" "}
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    fetch(`/reggie/registries/${id}`, {
                      method: "POST",
                      body: new URLSearchParams([["action", "export"]]),
                    })
                      .then((r) => r.blob())
                      .then((blob) => {
                        const url = URL.createObjectURL(blob);
                        const a = document.body.appendChild(
                          document.createElement("a")
                        );
                        a.textContent = "";
                        a.href = url;
                        a.download = "registry-export.csv";
                        a.click();
                        setTimeout(() => {
                          a.remove();
                          URL.revokeObjectURL(url);
                        }, 60000);
                      });
                  }}
                >
                  Export Registry as CSV
                </button>{" "}
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={print}
                >
                  View Printer-Friendly Version
                </button>
              </form>
            </div>
          </div>
        </div>
      </LegacyContainer>
    </StyleIsolation>
  ) : null;
}

export default () => (
  <LegacyMessageContext>
    <Registry />
  </LegacyMessageContext>
);
