/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Guide.scss";
import {
  Card,
  Collapsible,
  TextContainer,
  Icon,
  Layout,
  Page,
} from "@shopify/polaris";
import React, { useState, useCallback, useEffect } from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from "@shopify/polaris-icons";
import { useFetch, Link } from "../utils";

// function CompletedProgress({ progress }) {
//   const tasksComplete = progress;
//   const tasksTotal = 4;
//   const message = `${tasksComplete} of ${tasksTotal} tasks complete`;

//   return (
//     <div style={{ display: "flex", alignItems: "center" }}>
//       <span style={{ display: "inline-flex", whiteSpace: "nowrap" }}>
//         {message}
//       </span>
//       <div
//         style={{ width: "100%", display: "inline-flex", marginLeft: "1rem" }}
//       >
//         <ProgressBar
//           progress={(100 * tasksComplete) / tasksTotal}
//           size="small"
//         />
//       </div>
//     </div>
//   );
// }

function GuideStep({
  stepNumber,
  stepHTML,
  stepTitle,
  openStep,
  buttonSetStep,
  // decrementProgress,
  // incrementProgress,
}) {
  // const [completed, setCheckbox] = useState(false);

  // const handleProgress = useCallback(() => {
  //   if (completed) decrementProgress();
  //   else incrementProgress();
  // }, [completed, decrementProgress, incrementProgress]);

  // const handleCheckbox = useCallback(() => {
  //   setCheckbox(!completed);
  //   handleProgress();
  // }, [completed, handleProgress]);

  return (
    <div style={{ display: "flex" }} className="guide-step">
      {/* <div style={{ display: "inline-flex", alignItems: "start" }}>
        <Tooltip preferredPosition="above" content="Mark as done">
          <Button className="guide-check" onClick={handleCheckbox} plain>
            <Icon
              source={completed ? CircleTickMinor : CircleTickOutlineMinor}
            />
          </Button>
        </Tooltip>
      </div> */}
      {openStep === stepNumber ? null : (
        <div
          className="guide-button-div"
          style={{
            display: "inline-flex",
            alignItems: "start",
          }}
        >
          <button
            className="guide-button"
            type="button"
            data-step={stepNumber}
            onClick={buttonSetStep}
            style={{ background: "none", border: "none" }}
          >
            <div className="bodyMd">{stepTitle}</div>
          </button>
        </div>
      )}
      <div style={{ display: "inline-flex" }}>
        <Collapsible
          transition={false}
          open={openStep === stepNumber}
          id="basic-collapsible"
          expandOnPrint
        >
          <p>{stepTitle}</p>
          {stepHTML}
        </Collapsible>
      </div>
    </div>
  );
}

function GuideSteps() {
  const [openMaster, setOpenMaster] = useState(true);
  const [openStep, setOpenStep] = useState(0);
  // const [progress, setProgress] = useState(0);

  const handleToggle = useCallback(
    () => setOpenMaster((openMaster2) => !openMaster2),
    []
  );
  const setStepOne = useCallback(() => setOpenStep(1), []);
  const setStepTwo = useCallback(() => setOpenStep(2), []);
  const setStepThree = useCallback(() => setOpenStep(3), []);
  const setStepFour = useCallback(() => setOpenStep(4), []);

  const incrementProgress = useCallback(
    // () => setProgress((progress2) => progress2 + 1),
    () => {},
    []
  );
  const decrementProgress = useCallback(
    // () => setProgress((progress2) => progress2 - 1),
    () => {},
    []
  );

  const stepOneHTML = (
    <TextContainer>
      <p>
        {" "}
        Gift Reggie has many settings you can configure to tailor the experience
        for your customers.
      </p>
      <p>
        {" "}
        Navigate to the{" "}
        <Link url="/preferences?tab=tabIntegration">
          <strong>Store Integration Settings</strong>
        </Link>{" "}
        page.
      </p>
      <ul>
        <li>Enable or disable Wishlists on your store</li>
        <li>
          Modify &apos;Customer Account Integration&apos; to control who is able
          to create and manage registries on your store.
        </li>
        <li>
          Add css to change the look and feel of Gift Reggie related pages.
        </li>
      </ul>
      <p>
        {" "}
        Navigate to the{" "}
        <Link url="/preferences?tab=tabSettings">
          <strong>Registry Settings</strong>
        </Link>{" "}
        page.
      </p>
      <ul>
        <li>
          Add options for different Registry types. For example; wedding,
          birthday, babyshower.
        </li>
        <li>
          Add options to allow customers to report how they heard about your
          store.
        </li>
        <li>Modify options for store pickup on registry orders.</li>
        <li>Configure registry privacy settings</li>
        <li>
          Enable registry information fields to be recorded on the Shopify order
          record.
        </li>
      </ul>
    </TextContainer>
  );

  const stepTwoHTML = (
    <TextContainer>
      <p>
        {" "}
        Gift Reggie has a powerful email and notifications feature. This can
        allow you to notify registry owners when their items are purchased,
        notify you when a customer creates a new registry, provide stock
        warnings, and more.
      </p>
      <ol>
        <li>
          {" "}
          Navigate to the{" "}
          <Link url="/preferences?tab=tabEmail">
            <strong>Email & Notification Settings</strong>
          </Link>{" "}
          page.
        </li>
        <li>
          Set the &apos;Store Admin Email&apos; setting to the account you wish
          to recieve emails at.
        </li>
        <li>Click the checkbox on each email type you wish to enable.</li>
        <li>
          Apply subject and HTML body to each email. The HTML body field can
          also accept liquid.
        </li>
        <li>
          By default, emails are sent by Gift Reggie. If you wish to send emails
          from another address, setup SMTP under &apos;Account &gt; SMTP&apos;
        </li>
      </ol>
    </TextContainer>
  );

  const stepThreeHTML = (
    <TextContainer>
      <p className="tip">
        <strong>Tip:</strong> Wishlists are not enabled by default. You can
        enable them by clicking the <strong>Preferences</strong> dropdown in the
        top-right navigation and click <strong>Settings</strong>. Enabling it by
        ensuring the checkbox is checked and saving the page at the bottom.
      </p>
      <ol>
        <li>
          Login to your Shopify store administrative panel, and click{" "}
          <Link url="https://admin.shopify.com/admin/themes">
            <strong>Online Store</strong>
          </Link>{" "}
          on the left
        </li>
        <li>
          Find your store&apos;s default theme, or the one you wish to customize
          for Gift Reggie, and click <strong>Customize</strong>
        </li>
        <li>Navigate to a product page</li>
        <li>
          On the left side of your screen, click the &apos;App Embeds&apos; icon
          and toggle the &apos;Registry and Wishlist&apos; Gift Reggie setting
          on. This will activate Gift Reggie on your theme.
        </li>
        <li>
          Now you can place the buttons on your product page. Depending on what
          version of Shopify theme you are using, this step is slightly
          different.
        </li>
        <ul>
          <li>
            If you have a Shopify 2.0 theme, return to the sections tab. There
            should be a section for &apos;Product information&apos;. In here,
            click &apos;add block&apos; and select the
            &apos;Registry/Wishlist&apos; option. You can now drag this block
            around to place the buttons where you would like them.{" "}
          </li>
          <li>
            If you have a vintage Shopify 1.x theme, click on the &apos;Registry
            and Wishlist&apos; app embed to expand its settings. There will be a
            setting for &apos;Online Store Version&apos; with options 2.0 and
            1.x. Select 1.x. The app should automatically detect your product
            form and place the buttons on the page.{" "}
          </li>
        </ul>
        <li>
          To modify the button settings, you can click the app embeds icon and
          click on the &apos;Registry and Wishlist&apos; block. This will give
          you options to alter button colors, arrangement, behaviour, and more
          advanced css settings.{" "}
        </li>
        <li>
          If you would like more information about the install process or
          styling, we have a more detailed guide{" "}
          <Link
            external
            url="https://support.moddapps.com/hc/en-us/articles/15709043723283"
          >
            <strong>here</strong>
          </Link>
          .
        </li>
        <li>
          If you have any questions during the process, our support team will be
          happy to help you!
        </li>
      </ol>
    </TextContainer>
  );

  const stepFourHTML = (
    <TextContainer>
      <p id="reglinks">
        Below are the links that you may want to add to your store&apos;s main
        menu so that your customers can easily navigate your new Gift Registry
        feature.
      </p>
      <p>
        <strong>/apps/giftregistry</strong>: Your Gift Registry landing page.
      </p>
      <p>
        <strong>/apps/giftregistry/find</strong>: Where customers can search for
        a registry
      </p>
      <p>
        <strong>/apps/giftregistry/create</strong>: Where customers can create a
        registry
      </p>
      <p>
        <strong>/apps/giftregistry/manage</strong>: Where customers manage their
        registry
      </p>
      <p>
        <strong>/apps/giftregistry/wishlist</strong>: Where customers manage
        their wishlist (if enabled)
      </p>

      <p>
        To add these links to your Shopify store&apos;s main menu (the main
        navigation that is on every page), follow these steps:
      </p>

      <ol>
        <li>
          Login to your Shopify store administrative panel and navigate to your{" "}
          <Link url="https://admin.shopify.com/admin/menus">
            <strong>Navigation</strong>
          </Link>{" "}
          section.
        </li>
        <li>
          You will see all your store&apos;s menus, then click{" "}
          <strong>Main Menu</strong>
        </li>
        <li>
          Click the button labelled <strong>Add Menu Item</strong>, an item will
          appear in the menu list asking for a Name and Link type
        </li>
        <li>
          In the <strong>name</strong> field type &apos;Gift Registry&apos;
        </li>
        <li>
          In the <strong>link text entry box</strong> paste the Gift Registry
          landing page link: /apps/giftregistry
        </li>
        <li>Click on the link as it pops up over top of the text box.</li>
        <li>
          Click <strong>Save menu</strong>
        </li>
      </ol>

      <img src="/static/img/Gift-Reggie-Link.gif" alt="Gift Reggie" />

      <p>
        You can stop there if you&apos;d like or you can add the Find, Create
        and Manage links as dropdown options from your new Gift Registry link in
        your main menu.
      </p>

      <ol>
        <li>
          Return to the{" "}
          <Link url="https://admin.shopify.com/admin/menus">
            <strong>Navigation</strong>
          </Link>{" "}
          page by clicking Navigation on the left
        </li>
        <li>
          Click the <strong>Add menu</strong> button in the top righthand corner
          of the page
        </li>
        <li>
          Name the new menu <strong>Gift Registry</strong>
        </li>
        <li>
          Click the <strong>Add Menu Item</strong> button 3 times
        </li>
        <li>
          Name the items <strong>Find</strong>, <strong>Create</strong>,{" "}
          <strong>Manage</strong> respectively
        </li>
        <li>
          Paste each link into the text box it corresponds to (the links are
          found at the beginning of this step)
        </li>
        <li>Click on the link as it pops up over top of the text box.</li>
        <li>
          Click <strong>Save menu</strong>
        </li>
      </ol>
      <img
        src="/static/img/GiftReggie-Link-Dropdown.gif"
        alt="GiftReggie Link Dropdown Animation"
      />
    </TextContainer>
  );

  return (
    <div style={{ height: "200px" }}>
      <Card
        title="App Setup"
        actions={[
          {
            content: (
              <Icon source={openMaster ? ChevronUpIcon : ChevronDownIcon} />
            ),
            onAction: () => {
              handleToggle();
            },
          },
        ]}
        sectioned
      >
        {/* <Card.Section>
          <CompletedProgress progress={progress} />
        </Card.Section> */}
        <Collapsible
          open={openMaster}
          id="basic-collapsible"
          transition={false}
          expandOnPrint
        >
          <TextContainer>
            <GuideStep
              stepNumber={1}
              stepHTML={stepOneHTML}
              stepTitle="Configure Preferences"
              openStep={openStep}
              buttonSetStep={setStepOne}
              incrementProgress={incrementProgress}
              decrementProgress={decrementProgress}
            />
            <GuideStep
              stepNumber={2}
              stepHTML={stepTwoHTML}
              stepTitle="Email and Notifications settings"
              openStep={openStep}
              buttonSetStep={setStepTwo}
              incrementProgress={incrementProgress}
              decrementProgress={decrementProgress}
            />
            <GuideStep
              stepNumber={3}
              stepHTML={stepThreeHTML}
              stepTitle="Add the 'Add to Registry' and/or 'Add to Wishlist' Button to Your Store"
              openStep={openStep}
              buttonSetStep={setStepThree}
              incrementProgress={incrementProgress}
              decrementProgress={decrementProgress}
            />
            <GuideStep
              stepNumber={4}
              stepHTML={stepFourHTML}
              stepTitle="Add the Gift Reggie Links to Your Store's Menu"
              openStep={openStep}
              buttonSetStep={setStepFour}
              incrementProgress={incrementProgress}
              decrementProgress={decrementProgress}
            />
          </TextContainer>
        </Collapsible>
      </Card>
    </div>
  );
}

function Guide() {
  const authFetch = useFetch();
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!loaded) {
      authFetch("/reggie/guide", { headers: { Accept: "application/json" } })
        .then((response) => {
          console.log(response);
          setData(response);
          setLoaded(true);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error("Error getting page data.");
        });
    }
  }, [authFetch, loaded]);
  return data ? (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <GuideSteps />
        </Layout.Section>
      </Layout>
    </Page>
  ) : null;
}

export default Guide;
