/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from "react";
import { EmptyState } from "@shopify/polaris";
import { useFetch, useProfile, useRedirect, Link } from "./utils";

import StyleIsolation from "./components/StyleIsolation";
import LoadingAnimation from "./components/LoadingAnimation";

export default () => {
  const [data, setData] = useState(null);
  const fetch = useFetch();
  const redirect = useRedirect();
  const [error, setError] = useState(null);
  const [
    {
      shop: {
        shop_origin: shopOrigin,
        active_subscription: activeSubscription,
        scope: scopeString,
      },
      user: { account_owner: isStoreOwner },
    },
  ] = useProfile();

  const [authorizationInProgress, setAuthorizationInProgress] = useState(false);

  const scopes = String(scopeString).split(/,\s*/);

  const missingStorefrontAPI =
    !scopes.includes("unauthenticated_write_checkouts") ||
    !scopes.includes("unauthenticated_read_content") ||
    !scopes.includes("unauthenticated_read_metaobjects") ||
    !scopes.includes("unauthenticated_read_product_listings");

  const authorizeStorefrontAPI = useCallback(async () => {
    setAuthorizationInProgress(true);
    try {
      if (scopes.includes("unauthenticated_read_checkouts")) {
        await fetch("/api/profile/scopes/remove", {
          method: "POST",
          body: new URLSearchParams({
            scopes: "unauthenticated_read_checkouts",
          }),
        });
      }
      if (!scopes.includes("unauthenticated_write_checkouts")) {
        await fetch("/api/profile/scopes/add", {
          method: "POST",
          body: new URLSearchParams({
            scopes: "unauthenticated_write_checkouts",
          }),
        });
      }
      if (!scopes.includes("unauthenticated_read_content")) {
        await fetch("/api/profile/scopes/add", {
          method: "POST",
          body: new URLSearchParams({
            scopes: "unauthenticated_read_content",
          }),
        });
      }
      if (!scopes.includes("unauthenticated_read_metaobjects")) {
        await fetch("/api/profile/scopes/add", {
          method: "POST",
          body: new URLSearchParams({
            scopes: "unauthenticated_read_metaobjects",
          }),
        });
      }
      if (!scopes.includes("unauthenticated_read_product_listings")) {
        await fetch("/api/profile/scopes/add", {
          method: "POST",
          body: new URLSearchParams({
            scopes: "unauthenticated_read_product_listings",
          }),
        });
      }

      const { url } = await fetch(`/api/login?shop=${shopOrigin}`).then((r) =>
        r.json()
      );
      redirect(url);
    } catch (ex) {
      console.warn("authorization request failed");
      setAuthorizationInProgress(false);
    }
  }, [fetch, redirect, scopes, shopOrigin]);

  useEffect(() => {
    const oldAuthFetch = window.authFetch;
    window.authFetch = fetch;
    return () => {
      window.authFetch = oldAuthFetch;
    };
  });

  useEffect(() => {
    if (activeSubscription) {
      (async () => {
        try {
          const fetchDashboard = async () => {
            const response = await fetch("/reggie/", {
              headers: { Accept: "application/json" },
              redirect: "error",
            });
            if (response.ok) {
              const nextData = await response.json();
              setData(nextData);
            } else {
              throw response;
            }
          };

          const reconcile = async () => {
            const reconcileResponse = await fetch("/api/shop/reconcile", {
              headers: { Accept: "application/json" },
            });
            if (!reconcileResponse.ok) {
              throw reconcileResponse;
            }
          };

          await fetchDashboard().catch(() => reconcile().then(fetchDashboard));
        } catch (ex) {
          setError(true);
          console.error("Fetching Dashboard Data", ex);
        }
      })();
    } else {
      redirect("/account");
    }
  }, [activeSubscription, fetch, redirect]);

  return data ? (
    <StyleIsolation>
      <div id="main-content">
        <div className="container">
          <div className="content">
            <div className="row-fluid">
              <div className="span12">
                <div id="message-display" hidden />

                {missingStorefrontAPI && isStoreOwner ? (
                  <div className="alert alert-warning">
                    Please{" "}
                    <button
                      type="button"
                      disabled={authorizationInProgress}
                      onClick={authorizeStorefrontAPI}
                      className="button-is-link"
                    >
                      click here
                    </button>{" "}
                    to authorize Gift Reggie to use the Shopify Storefront API.{" "}
                    <a
                      href="https://support.moddapps.com/hc/en-us/articles/22763497499411"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read More
                    </a>
                  </div>
                ) : null}

                <div className="row-fluid">
                  <h2>Welcome to Gift Reggie!</h2>
                  <p>
                    Congrats! Gift Reggie is automatically set up on your site,
                    at{" "}
                    <a
                      target="_blank"
                      href={`https://${shopOrigin}${data.PROXY_CLEAN_URL}`}
                      rel="noreferrer"
                    >
                      /apps/giftregistry
                    </a>
                    . You can link to that main page, or any other page inside
                    our app directly from your navbar (by adding a link in the
                    &quot;Navigation&quot; tab in your Shopify admin panel), or
                    anywhere else on your site. If you&apos;ve enabled
                    wishlists, by going to{" "}
                    <Link to="/preferences">Preferences</Link>, and checking the
                    &quot;Wishlists&quot; checkbox, customers can open and
                    manage their wishlists by going to{" "}
                    <a
                      target="_blank"
                      href={`https://${shopOrigin}${data.PROXY_CLEAN_URL}/wishlist`}
                      rel="noreferrer"
                    >
                      /apps/giftregistry/wishlist
                    </a>
                    .
                  </p>
                  {/*
                  TODO:
                  <p>&nbsp;</p>
                  <p>
                    <strong>
                      For more information and tips on how to perfect your Gift
                      Reggie experience, please check out our{" "}
                      <a href="about:blank">Setup &amp; Integration Guide</a>.
                    </strong>
                  </p>
                  */}
                </div>
                <p>&nbsp;</p>
                <div className="row-fluid">
                  <h2>Setup</h2>
                  <p>
                    Need help setting up the app? Head over to our{" "}
                    <Link to="/guide">Setup Guide</Link> or{" "}
                    <a
                      target="_blank"
                      href="https://support.moddapps.com/hc/en-us/categories/360005168274-Gift-Reggie"
                      rel="noreferrer"
                    >
                      Documentation pages
                    </a>
                    .
                  </p>
                </div>
                <p>&nbsp;</p>

                <div className="row-fluid">
                  <h2>Shop Dashboard</h2>
                  <p>
                    Stay on top of all your Gift Reggie activity at a glance.
                  </p>
                </div>
                <hr />
                <div className="row-fluid" hidden>
                  <form action="?" method="get">
                    <div className="span6">
                      Select Dates:
                      <input
                        type="text"
                        name="start-date"
                        placeholder="Start Date"
                        id="start-date"
                      />
                      TO
                      <input
                        type="text"
                        name="end-date"
                        placeholder="End Date"
                        id="end-date"
                      />
                      <button type="button" className="btn">
                        Filter
                      </button>
                    </div>
                    <div className="span6">
                      <h2>Recent Activity</h2>
                    </div>
                  </form>
                </div>

                <div className="row-fluid">
                  <div className="span4">
                    <div
                      className="summary"
                      style={{ borderleft: "4px #cf534d solid !important" }}
                    >
                      <span>{data.TODAY_SALES}</span> TODAY&apos;S SALES
                    </div>
                    <div
                      className="summary"
                      style={{ borderleft: "4px #cf534d solid !important" }}
                    >
                      <span>{data.TOTAL_SALES}</span> TOTAL SALES
                    </div>
                    <div
                      className="summary"
                      style={{ borderleft: "4px #cf534d solid !important" }}
                    >
                      <span>{data.POTENTIAL_SALES}</span> POTENTIAL SALES
                    </div>
                    <div
                      className="summary"
                      style={{ borderleft: "4px #cf534d solid !important" }}
                    >
                      <span>{data.TOTAL_REGISTRIES}</span> TOTAL ACTIVE
                      REGISTRIES
                    </div>
                    <div
                      className="summary"
                      style={{ borderleft: "4px #cf534d solid !important" }}
                    >
                      <span>{data.TOTAL_WISHLISTS}</span> TOTAL WISHLISTS
                    </div>
                  </div>
                  <div className="span6 offset2 activity">
                    {data.ACTIVITY?.map?.((innerData) => (
                      <div
                        key={`${innerData.REGISTRY_ID}\f${innerData.WISHLIST_ID}\f${innerData.DATE}`}
                      >
                        {innerData.ACTIVITY_EXPIRE ? (
                          <>
                            Registry{" "}
                            <Link to={`/registries/${innerData.REGISTRY_ID}`}>
                              {innerData.REGISTRY_NAME} (#
                              {innerData.REGISTRY_ID})
                            </Link>{" "}
                            has expired.
                          </>
                        ) : null}
                        {innerData.ACTIVITY_CANCEL ? (
                          <>
                            Order{" "}
                            <Link
                              to={`//${shopOrigin}/admin/orders/${innerData.ORDER_ID}`}
                            >
                              {innerData.ORDER_NAME ? (
                                <>{innerData.ORDER_NAME}</>
                              ) : (
                                <>#{innerData.ORDER_ID}</>
                              )}
                            </Link>{" "}
                            for registry{" "}
                            <Link to={`/registries/${innerData.REGISTRY_ID}`}>
                              {innerData.REGISTRY_NAME} (#
                              {innerData.REGISTRY_ID})
                            </Link>
                            , has been cancelled.
                          </>
                        ) : null}
                        {innerData.ACTIVITY_OPEN ? (
                          <>
                            Registry{" "}
                            <Link to={`/registries/${innerData.REGISTRY_ID}`}>
                              {innerData.REGISTRY_NAME} (#
                              {innerData.REGISTRY_ID})
                            </Link>{" "}
                            has been opened.
                          </>
                        ) : null}
                        {innerData.ACTIVITY_CLOSE ? (
                          <>
                            Registry{" "}
                            <Link to={`/registries/${innerData.REGISTRY_ID}`}>
                              {innerData.REGISTRY_NAME} (#
                              {innerData.REGISTRY_ID})
                            </Link>{" "}
                            has been closed.
                          </>
                        ) : null}
                        {innerData.ACTIVITY_ORDER ? (
                          <>
                            Order{" "}
                            <Link
                              to={`//${shopOrigin}/admin/orders/${innerData.ORDER_ID}`}
                            >
                              {innerData.ORDER_NAME ? (
                                <>{innerData.ORDER_NAME}</>
                              ) : (
                                <>#{innerData.ORDER_ID}</>
                              )}
                            </Link>{" "}
                            has been placed for registry{" "}
                            <Link to={`/registries/${innerData.REGISTRY_ID}`}>
                              {innerData.REGISTRY_NAME} (#
                              {innerData.REGISTRY_ID})
                            </Link>
                            , totalling {innerData.PRICE}.
                          </>
                        ) : null}
                        {innerData.ACTIVITY_WISHLIST ? (
                          <>
                            Wishlist{" "}
                            <Link to={`/wishlists/${innerData.WISHLIST_ID}`}>
                              {innerData.WISHLIST_EMAIL} (#
                              {innerData.WISHLIST_ID})
                            </Link>{" "}
                            has been created.
                          </>
                        ) : null}
                        <span className="date">({innerData.DATE})</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row" />
          </div>
        </div>
      </div>
      <div className="copyright">
        Gift Reggie - &copy; {new Date().getUTCFullYear()}{" "}
        <a href="http://moddapps.com/" target="_blank" rel="noreferrer">
          Modd Apps
        </a>
      </div>
    </StyleIsolation>
  ) : (
    <>
      {!data && !error && <LoadingAnimation />}
      {error && (
        <EmptyState>
          <p>App is unavailable right now, please try again later</p>
        </EmptyState>
      )}
    </>
  );
};
