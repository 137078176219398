import React, { createContext, useContext, useEffect, useState } from "react";
import Tracker from "@openreplay/tracker";
import { useProfile } from "../utils";
import MockStore from "../MockStore";

const OpenReplayContext = createContext(null);

export const OpenReplayContextProvider = ({
  children,
  projectKey = process.env.REACT_APP_OPEN_REPLAY_PROJECT_KEY ||
    "sJXkciNbV0xfvUT3AbJQ",
  ingestPoint = process.env.REACT_APP_OPEN_REPLAY_INGEST ||
    "https://or.moddsvc.app/ingest",
}) => {
  const [profile] = useProfile();
  const [tracker, setTracker] = useState(null);

  useEffect(() => {
    if (projectKey && ingestPoint) {
      setTracker(
        new Tracker({
          projectKey,
          ingestPoint,
          localStorage: new MockStore("localStorage"),
          sessionStorage: new MockStore("sessionStorage"),
          __DISABLE_SECURE_MODE: process.env.NODE_ENV === "development",
        })
      );
      console.log("Initalized OpenReplay");
    }
  }, [ingestPoint, projectKey]);

  useEffect(() => {
    if (profile && tracker) {
      const traderData = profile.user.watchtower
        ? {
            userID: "watchtower",
            metadata: {
              shopify_shop_id: profile.shop.shopify_shop_id,
              myshopify_domain: profile.shop.shop_origin,
              plan: profile.shop.active_subscription?.plan_name,
              days_installed: profile.shop.days_installed,
            },
          }
        : {
            userID: profile.user.email,
            metadata: {
              account_owner: profile.user.account_owner,
              shopify_user_id: profile.user.shopify_user_id,
              shopify_shop_id: profile.shop.shopify_shop_id,
              myshopify_domain: profile.shop.shop_origin,
              plan: profile.shop.active_subscription?.plan_name,
              days_installed: profile.shop.days_installed,
              name: profile.user.name,
            },
          };

      tracker.start(traderData).then(
        () => console.log("Started OpenReplay"),
        (error) => console.error(error)
      );
    }
  }, [profile, tracker]);

  return (
    <OpenReplayContext.Provider value={tracker}>
      {children}
    </OpenReplayContext.Provider>
  );
};

export const useOpenReplay = () => useContext(OpenReplayContext);

export default useOpenReplay;
