import React from "react";

function CreatePagination({ maxPages, paginationSize = 3, page, setPage }) {
  if (maxPages > 1) {
    const currentPage = +page || 1;
    const minPage = Math.max(1, currentPage - paginationSize);
    const maxPage = Math.min(currentPage + paginationSize, maxPages);

    return (
      <>
        {" - "}
        {[...Array(maxPage - minPage + 1).keys()].map((offset) => (
          <React.Fragment key={`page-${minPage + offset}`}>
            {minPage + offset === page ? (
              <b>{minPage + offset}</b>
            ) : (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setPage(minPage + offset);
                }}
                className="button-is-link"
              >
                {minPage + offset}
              </button>
            )}
            {" - "}
          </React.Fragment>
        ))}
      </>
    );
  }
  return null;
}

export default CreatePagination;
