import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Page, Layout, Card, Text, BlockStack } from "@shopify/polaris";
import { useFetch } from "./utils";
import LoadingAnimation from "./components/LoadingAnimation";

const GroupBuyContainer = ({ data }) => {
  if (!data) return null;

  const ContributionsBlock = () => (
    <pre>{JSON.stringify(data?.contributions, null, 2)}</pre>
  );

  return (
    <BlockStack gap="400">
      <ContributionsBlock />
      <hr />
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </BlockStack>
  );
};

export const GroupBuyPage = () => {
  const { id } = useParams();
  const authFetch = useFetch();

  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState(id);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () =>
      authFetch(`/api/group_buy/${id}`).then(async (r) => r.json());

    if (loading) {
      getData()
        .then((jsonResponse) => {
          if (jsonResponse?.error) {
            setError(jsonResponse.error);
          } else {
            setData(jsonResponse);
            setTitle(
              `${jsonResponse.customer.first_name} ${jsonResponse.customer.last_name} - ${id}`
            );
          }
        })
        .finally(() => {
          setLoading(!loading);
        });
    }
  }, [loading, setLoading, authFetch, setError, id]);

  return (
    <Page
      fullWidth
      title={title}
      breadcrumbs={[{ content: "GroupBuy", url: "/group_buy" }]}
    >
      <Layout>
        <Layout.Section>
          <Card>
            {loading && <LoadingAnimation />}
            {!loading && <GroupBuyContainer data={data} />}
          </Card>
          {error && (
            <Text as="span" color="critical">
              {error}
            </Text>
          )}
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default GroupBuyPage;
