import React from "react";
import { Card, Page, EmptyState } from "@shopify/polaris";
import { useProfile } from "../utils";
import LoadingAnimation from "./LoadingAnimation";

function canRead(user, permission) {
  return !!(user.permissions === null || user.permissions[permission]);
}

function NeedPermissionPage() {
  return (
    <Page>
      <Card sectioned>
        <EmptyState heading="You don't have permission to access this page.">
          <p>Please contact the Shopify account owner for more details.</p>
        </EmptyState>
      </Card>
    </Page>
  );
}

function PermissionGuard({ permission, children }) {
  const [profile] = useProfile();

  if (profile) {
    if (!canRead(profile.user, permission)) {
      return <NeedPermissionPage />;
    }
    return children;
  }
  return <LoadingAnimation />;
}

export default PermissionGuard;
