import React, { useState, useEffect, useCallback } from "react";
import { useProfile, useFetch, Link } from "../utils";
import StyleIsolation from "../components/StyleIsolation";
import LegacyContainer, {
  useGiftReggieFetch,
} from "../components/LegacyContainer";
import LegacyMessageContext from "../components/LegacyMessageContext";

function Sorter({ forOrder, order, dir, setOrder, setDir }) {
  const newDir = forOrder === order && dir === "asc" ? "desc" : "asc";
  const dirIcon = newDir === "desc" ? "\u2B06\uFE0F" : "\u2B07\uFE0F";
  const icon = forOrder === order ? dirIcon : "\u2935\uFE0F";

  const click = useCallback(() => {
    setOrder(forOrder);
    setDir(newDir);
  }, [forOrder, newDir, setDir, setOrder]);

  return (
    <button
      type="button"
      className="button-is-link"
      title={`Sort ${newDir}`}
      onClick={click}
      style={{ marginLeft: "1em" }}
    >
      {icon}
    </button>
  );
}

function CreatePagination({ maxPages, paginationSize = 3, page, setPage }) {
  if (maxPages > 1) {
    const currentPage = +page || 1;
    const minPage = Math.max(1, currentPage - paginationSize);
    const maxPage = Math.min(currentPage + paginationSize, maxPages);

    return (
      <>
        {" - "}
        {[...Array(maxPage - minPage + 1).keys()].map((offset) => (
          <React.Fragment key={`page-${minPage + offset}`}>
            {minPage + offset === page ? (
              <b>{minPage + offset}</b>
            ) : (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setPage(minPage + offset);
                }}
                className="button-is-link"
              >
                {minPage + offset}
              </button>
            )}
            {" - "}
          </React.Fragment>
        ))}
      </>
    );
  }
  return null;
}

function OrderStatus() {
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const [
    {
      shop: { shop_origin: shopOrigin },
    },
  ] = useProfile();
  const fetch = useGiftReggieFetch();
  const baseFetch = useFetch();

  const [status, setStatus] = useState("open");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [title, setTitle] = useState("");

  const [page, setPage] = useState(1);
  const [dir, setDir] = useState(null);
  const [order, setOrder] = useState(null);

  const genURL = useCallback(() => {
    const url = new URL("/reggie/orders", window.location);

    if (status) {
      url.searchParams.append("status", status);
    }

    if (startDate) {
      const d = new Date(startDate);
      url.searchParams.append(
        "start-date",
        `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
      );
    }

    if (endDate) {
      const d = new Date(endDate);
      url.searchParams.append(
        "end-date",
        `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
      );
    }

    if (title) {
      url.searchParams.append("title", title);
    }

    url.searchParams.append("page", page || 1);
    if (dir && order) {
      url.searchParams.append("dir", dir);
      url.searchParams.append("order", order);
    }

    return url;
  }, [page, dir, order, status, startDate, endDate, title]);

  useEffect(() => {
    if (!loaded) {
      const url = genURL();

      fetch(url, { headers: { Accept: "application/json" } })
        .then((nextData) => {
          setData(nextData);
          setLoaded(true);
        })
        .catch((ex) => {
          console.error("Fetching Dashboard Data", ex);
        });
    }
  }, [fetch, loaded, genURL]);

  const changeStatus = useCallback((e) => {
    setStatus(e.target.value);
  }, []);

  const submit = useCallback((e) => {
    e.preventDefault();
    setPage(1);
    setLoaded(false);
  }, []);

  const clear = useCallback((e) => {
    e.preventDefault();

    setStatus("open");
    setStartDate("");
    setEndDate("");
    setTitle(null);

    setLoaded(false);
  }, []);

  const updatePage = useCallback((pageNumber) => {
    setPage(pageNumber);
    setLoaded(false);
  }, []);

  const updateDir = useCallback((newDir) => {
    setDir(newDir);
    setLoaded(false);
  }, []);

  const changeTitle = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const changeStartDate = useCallback((e) => {
    setStartDate(e.target.value);
  }, []);

  const changeEndDate = useCallback((e) => {
    setEndDate(e.target.value);
  }, []);

  return data ? (
    <StyleIsolation>
      <LegacyContainer>
        <h3>Order Status</h3>
        <hr />
        <p>
          Here, you can view all line items from orders that are associated with
          your registries. Each row in this table represents a single line item
          to be fulfilled in your store.
        </p>
        <p>
          Clicking on the line item&apos;s order ID will bring you to Shopify,
          focused on the order in question.
        </p>
        <p>
          Cicking on the product ID will open the product&apos;s page in your
          store, for you to examine more closely, should you desire.
        </p>
        <p>
          And finally, clicking on the registry ID number, will open Gift Reggie
          to that registry&apos;s page, where you can view the details of the
          registry.
        </p>

        <hr />

        <div className="row-fluid">
          <div className="span12">
            <form
              onSubmit={submit}
              id="order-status-form"
              style={{ margin: 0 }}
            >
              Show{" "}
              <select
                id="order-status"
                name="status"
                style={{ width: "auto" }}
                onChange={changeStatus}
              >
                <option>open</option>
                <option>closed</option>
                <option>cancelled</option>
                <option>all</option>
              </select>{" "}
              line items that have a title like{" "}
              <input
                id="order-title"
                style={{ width: "128px" }}
                type="text"
                name="title"
                placeholder="anything"
                onChange={changeTitle}
                value={title}
              />{" "}
              and were ordered between{" "}
              <input
                id="order-start-date"
                name="start-date"
                style={{ width: "128px" }}
                type="date"
                placeholder="the distant past"
                onChange={changeStartDate}
                value={startDate}
              />{" "}
              and{" "}
              <input
                type="date"
                style={{ width: "128px" }}
                id="order-end-date"
                name="end-date"
                placeholder="the far future"
                onChange={changeEndDate}
                value={endDate}
              />{" "}
              <button type="submit" className="btn">
                Search
              </button>{" "}
              <button
                type="button"
                id="filter-clear"
                className="btn"
                onClick={clear}
              >
                Clear
              </button>
            </form>
          </div>
        </div>

        <div className="row-fluid">
          <div className="span12">
            <table className="table table-striped registry-table">
              <thead>
                <tr>
                  <th id="heading-order_id">
                    Order ID
                    <Sorter
                      forOrder="order_id"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-name">
                    Order Name
                    <Sorter
                      forOrder="name"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th>Type</th>
                  <th id="heading-created">
                    Created
                    <Sorter
                      forOrder="created"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-cancelled">
                    Cancelled
                    <Sorter
                      forOrder="cancelled"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th>Status</th>
                  <th id="heading-title">
                    Title
                    <Sorter
                      forOrder="title"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-product_id">
                    Product ID
                    <Sorter
                      forOrder="product_id"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-variant_id">
                    Variant ID
                    <Sorter
                      forOrder="variant_id"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-sku">
                    SKU
                    <Sorter
                      forOrder="sku"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-price">
                    Price
                    <Sorter
                      forOrder="price"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-quantity">
                    Quantity
                    <Sorter
                      forOrder="quantity"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th>Registry ID</th>
                </tr>
              </thead>
              <tbody>
                {data.ORDERS?.length ? (
                  data.ORDERS.map(
                    ({
                      CANCELLED,
                      CLOSED,
                      CREATED,
                      // ID,
                      IN_STORE_PICKUP,
                      ORDER_ID,
                      ORDER_NAME,
                      PRICE,
                      PRODUCT_ID,
                      QUANTITY,
                      REGISTRY_ID,
                      SKU,
                      TITLE,
                      // UPDATED,
                      VARIANT_ID,
                    }) => (
                      <tr key={ORDER_ID}>
                        <td>
                          <a
                            target="_blank"
                            href={`https://${shopOrigin}/admin/orders/${ORDER_ID}`}
                            rel="noreferrer"
                          >
                            {ORDER_ID}
                          </a>
                        </td>
                        <td>{ORDER_NAME}</td>
                        <td>{IN_STORE_PICKUP ? "Pick-Up" : "Ship"}</td>
                        <td>{CREATED}</td>
                        <td>{CANCELLED || "N/A"}</td>
                        <td>
                          {CANCELLED ? (
                            <>{CLOSED ? "Closed" : "Open"}</>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>{TITLE}</td>
                        <td>
                          <a
                            target="_blank"
                            href={`https://${shopOrigin}/admin/products/${PRODUCT_ID}`}
                            rel="noreferrer"
                          >
                            {PRODUCT_ID}
                          </a>
                        </td>
                        <td>{VARIANT_ID}</td>
                        <td>{SKU}</td>
                        <td>{PRICE}</td>
                        <td>{QUANTITY}</td>
                        <td>
                          <Link to={`/registries/${REGISTRY_ID}`}>
                            {REGISTRY_ID}
                          </Link>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={13}>
                      You have no orders matching your filtering criteria.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div id="registry-pagination" style={{ textAlign: "center" }}>
              <CreatePagination
                maxPages={data.MAX_PAGES}
                page={page}
                setPage={updatePage}
              />
            </div>
          </div>
        </div>

        <div className="row-fluid">
          <div className="span6">
            <p>
              <button
                type="button"
                id="export-csv"
                className="btn btn-reggie"
                onClick={() => {
                  const url = genURL();
                  baseFetch(url, {
                    method: "POST",
                    body: new URLSearchParams([["action", "export"]]),
                  })
                    .then((r) => r.blob())
                    .then((blob) => {
                      const newurl = URL.createObjectURL(blob);
                      const a = document.body.appendChild(
                        document.createElement("a")
                      );
                      a.textContent = "";
                      a.href = newurl;
                      a.download = "orders-export.csv";
                      a.click();
                      setTimeout(() => URL.revokeObjectURL(url), 60000);
                    });
                }}
              >
                Export Listed Orders as CSV
              </button>
            </p>
          </div>
        </div>
      </LegacyContainer>
    </StyleIsolation>
  ) : null;
}

export default () => (
  <LegacyMessageContext>
    <OrderStatus />
  </LegacyMessageContext>
);
