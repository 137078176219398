import { useEffect } from "react";

function LoadingAnimation() {
  useEffect(() => {
    if (window.shopify) {
      window.shopify.loading(true);
      return () => {
        window.shopify.loading(false);
      };
    }
    return () => {};
  }, []);
}

export default LoadingAnimation;
