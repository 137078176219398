/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";

import { Banner, Link } from "@shopify/polaris";

import { useProfile } from "../utils";
import { SubscriptionContext } from "./SubscriptionContext";

const moment = require("moment");

export default () => {
  const { activeSubscriptions } = useContext(SubscriptionContext);

  const context = useProfile()[0];
  const activeSubscription = activeSubscriptions.filter(
    (s) => s.status === "active"
  );

  const banner = [];

  if (moment(activeSubscription.trial_end_date).subtract(moment()) > 0)
    banner.push(
      <Banner
        title={`Trial Expires in ${activeSubscription[0].trial_days_left} days`}
        status="info"
      />
    );

  const pendingcharge = activeSubscriptions.find((s) => s.status === "pending");
  if (pendingcharge) {
    const otherSubscription = activeSubscriptions.filter(
      (s) => s.status === "active"
    );
    const action = context.user.account_owner
      ? {
          content: "Approve Charge",
          url: pendingcharge.confirmation_url,
        }
      : null;

    banner.push(
      <Banner
        title="Changes to your subscription"
        status={otherSubscription ? "warning" : "error"}
        action={action}
      >
        There is a pending charge on your account that must be accepted to
        activate your new subscription. Please click{" "}
        <Link external url={pendingcharge.confirmation_url}>
          here
        </Link>{" "}
        to be redirected to Shopify to accept the charge. Note, you will need
        appropriate permissions, if you encounter an error please ask the
        Shopify account owner to perform with action.
      </Banner>
    );
  }
  return <>{banner}</>;
};
