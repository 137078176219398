/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useFetch } from "../../utils";

const Button = ({ onClick, children }) => (
  <button className="btn btn-reggie" onClick={onClick} type="button">
    {children}
  </button>
);

const LOAD = Symbol("LOAD");
const REPLACE = Symbol("REPLACE");
const RESET = Symbol("RESET");

const TRANSLATION_KEYS = [
  { key: "i18n.wishlist.idleAdd", default: "Add to Wishlist" },
  { key: "i18n.wishlist.idleRemove", default: "Remove from Wishlist" },
  { key: "i18n.wishlist.pending", default: "..." },
  { key: "i18n.wishlist.successAdd", default: "Added" },
  { key: "i18n.wishlist.successRemove", default: "Removed" },
  { key: "i18n.wishlist.noVariant", default: "Add to Wishlist" },
  { key: "i18n.wishlist.error", default: "Error" },
  { key: "i18n.registry.idleAdd", default: "Add to Registry" },
  { key: "i18n.registry.idleRemove", default: "Remove from Registry" },
  { key: "i18n.registry.pending", default: "..." },
  { key: "i18n.registry.successAdd", default: "Added" },
  { key: "i18n.registry.successRemove", default: "Removed" },
  { key: "i18n.registry.noVariant", default: "Add to Registry" },
  { key: "i18n.registry.error", default: "Error" },
];

const SHOP_QUERY = `{
  shop {
    id
    metafield(namespace: "gift-reggie", key: "i18n") {
      id
      value
    }
  }
  shopLocales {
    locale
    primary
  }
}`;

const TRANSLATION_QUERY = `query translation($id: ID!, $locale: String!) {
  translatableResource(resourceId: $id) {
    translatableContent {
      digest
    }
    translations(locale: $locale) {
      value
    }
  }
}`;

const UPDATE_METAFIELD_MUTATION = `mutation updateMetafield($owner: ID!, $value: String!) {
  metafieldsSet(
    metafields: [{ownerId: $owner, namespace: "gift-reggie", key: "i18n", value: $value, type: "json"}]
  ) {
    metafields {
      id
    }
    userErrors {
      field
      message
      code
    }
  }
}`;

const REMOVE_METAFIELD_MUTATION = `mutation removeMetafield($id: ID!) {
  metafieldDelete(input: {id: $id}) {
    userErrors {
      field
      message
    }
  }
}`;

const CREATE_METAFIELD_VISIBILITY_MUTATION = `mutation publishMetafield {
  metafieldStorefrontVisibilityCreate(
    input: {namespace: "gift-reggie", key: "i18n", ownerType: SHOP}
  ) {
    userErrors {
      field
      message
    }
  }
}`;

const UPDATE_METAFIELD_TRANSLATION_MUTATION = `mutation updateTranslation($id: ID!, $locale: String!, $value: String!, $translatableContentDigest: String!) {
  translationsRegister(
    resourceId: $id
    translations: [{key: "value", locale: $locale, translatableContentDigest: $translatableContentDigest, value: $value}]
  ) {
    userErrors {
      field
      message
    }
  }
}`;

const REMOVE_METAFIELD_TRANSLATION_MUTATION = `mutation removeTranslation($id: ID!, $locale: String!) {
  translationsRemove(
    resourceId: $id
    locales: [$locale]
    translationKeys: ["value"]
  ) {
    userErrors {
      field
      message
    }
  }
}`;

const inflate = (keys) =>
  Object.entries(keys).reduce((acc, [key, value]) => {
    // perform a deep merge here
    // heavy assumption all keys are 3 long here
    const [a, b, c] = key.split(/\./);
    return {
      ...acc,
      [a]: { ...acc?.[a], [b]: { ...acc?.[a]?.[b], [c]: value } },
    };
  }, {});

// heavy assumption all keys are 3 long here
const flatten = (v0) =>
  Object.fromEntries(
    Object.entries(v0).flatMap(([k1, v1]) =>
      Object.entries(v1).flatMap(([k2, v2]) =>
        Object.entries(v2).map(([k3, v3]) => [`${k1}.${k2}.${k3}`, v3])
      )
    )
  );

const DEFAULT_TRANSLATIONS = JSON.stringify(
  inflate(
    Object.fromEntries(
      TRANSLATION_KEYS.map(({ key, default: value }) => [key, value])
    )
  )
);

const translationReducer = (
  previous,
  { action, locale, key, value, content }
) => {
  const next = { ...previous };
  switch (action) {
    case REPLACE:
      return { ...previous, [locale]: { ...previous[locale], [key]: value } };
    case LOAD:
      return { ...previous, [locale]: flatten(content) };
    case RESET:
      return {};
    default:
      return next;
  }
};

function WidgetTranslation({ localeMap }) {
  const [data, setData] = useState(null);
  const [translatableContentDigest, setTranslatableContentDigest] =
    useState(null);
  const [loaded, setLoaded] = useState(false);

  const [translations, dispatch] = useReducer(translationReducer, {});

  const fetch = useFetch();

  const gqlShopId = data?.shop?.id;
  const gqlMetafieldId = data?.shop?.metafield?.id;

  const hasTranslationMetafield = !!gqlMetafieldId;

  const defaultLocale = useMemo(
    () => data?.shopLocales?.find?.(({ primary }) => primary)?.locale,
    [data?.shopLocales]
  );

  const doQuery = useCallback(
    (query, variables) =>
      fetch("/api/shopify/2024-01/graphql.json", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ query, variables }),
      })
        .then((r) => r.json())
        .then((response) => response.data),
    [fetch]
  );

  // ---

  const createTranslationMetafield = useCallback(
    () =>
      Promise.all([
        doQuery(UPDATE_METAFIELD_MUTATION, {
          owner: gqlShopId,
          value: DEFAULT_TRANSLATIONS,
        }),
        doQuery(CREATE_METAFIELD_VISIBILITY_MUTATION),
      ]).finally(() => setLoaded(false)),
    [doQuery, gqlShopId]
  );

  // ---

  const updateTranslationMetafield = useCallback(() => {
    if (translations[defaultLocale])
      return Promise.all([
        doQuery(UPDATE_METAFIELD_MUTATION, {
          owner: gqlShopId,
          value: JSON.stringify(inflate(translations[defaultLocale])),
        }),
        doQuery(CREATE_METAFIELD_VISIBILITY_MUTATION),
      ]).finally(() => setLoaded(false));
    console.warn("default translation is blank");
    return null;
  }, [defaultLocale, doQuery, gqlShopId, translations]);

  const destroyTranslationMetafield = useCallback(() => {
    if (
      confirm(
        "Are you sure you wish to revert the product page button text to the default text?"
      )
    )
      doQuery(REMOVE_METAFIELD_MUTATION, { id: gqlMetafieldId }).finally(() =>
        setLoaded(false)
      );
  }, [doQuery, gqlMetafieldId]);

  const saveTranslation = useCallback(
    (locale) => {
      const translationData = {};
      TRANSLATION_KEYS.forEach(({ key, default: defaultValue }) => {
        translationData[key] = translations[locale]
          ? translations[locale][key] ?? ""
          : defaultValue;
      });

      return doQuery(UPDATE_METAFIELD_TRANSLATION_MUTATION, {
        id: gqlMetafieldId,
        locale,
        translatableContentDigest,
        value: JSON.stringify(inflate(translationData)),
      }).finally(() => setLoaded(false));
    },
    [doQuery, gqlMetafieldId, translatableContentDigest, translations]
  );
  const destroyTranslation = useCallback(
    (locale) => {
      doQuery(REMOVE_METAFIELD_TRANSLATION_MUTATION, {
        id: gqlMetafieldId,
        locale,
      }).finally(() => setLoaded(false));
    },
    [doQuery, gqlMetafieldId]
  );

  // ---

  const updateTranslation = useCallback((locale, key, value) => {
    dispatch({ action: REPLACE, locale, key, value });
  }, []);

  useEffect(() => {
    if (!loaded) {
      dispatch({
        action: RESET,
      });
      doQuery(SHOP_QUERY).then(async (newData) => {
        setData(newData);
        if (newData?.shop?.metafield?.value) {
          try {
            dispatch({
              action: LOAD,
              content: JSON.parse(newData.shop.metafield.value),
              locale:
                newData.shopLocales.find((shopLocale) => shopLocale.primary)
                  ?.locale || "en",
            });
          } catch {
            // ignore
          }
        }
        if (newData.shop.metafield?.id) {
          await Promise.allSettled(
            newData.shopLocales
              .filter(({ primary }) => !primary)
              .map(({ locale }) =>
                doQuery(TRANSLATION_QUERY, {
                  id: newData.shop.metafield.id,
                  locale,
                }).then((translationData) => {
                  if (
                    translationData.translatableResource.translatableContent
                      .length
                  ) {
                    setTranslatableContentDigest(
                      translationData.translatableResource
                        .translatableContent[0].digest
                    );
                  }
                  if (translationData.translatableResource.translations[0]) {
                    dispatch({
                      action: LOAD,
                      content: JSON.parse(
                        translationData.translatableResource.translations[0]
                          .value
                      ),
                      locale,
                    });
                  }
                })
              )
          );
        }
        setLoaded(true);
      });
    }
  }, [doQuery, loaded]);

  return loaded ? (
    <>
      <h3>Product Page App Extention Translations</h3>
      <p>
        This allows overrides to the specified to text on the App Extentions.{" "}
        <b>
          If you use custom translations, you must provide translations for each
          locale your shop uses.
        </b>{" "}
        If you omit a translation, the default shop&apos;s locale will be used
      </p>
      {hasTranslationMetafield ? (
        <>
          <table>
            <thead>
              <tr>
                <th>Translation Key</th>
                {data.shopLocales.map(({ locale, primary }) => (
                  <th key={locale}>
                    {primary ? (
                      <i>{localeMap.get(locale) || locale}</i>
                    ) : (
                      localeMap.get(locale) || locale
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {TRANSLATION_KEYS.map(({ key }) => (
                <tr key={key}>
                  <td>{key}</td>
                  {data.shopLocales.map(({ locale }) => (
                    <td key={locale}>
                      {translations[locale] ? (
                        <input
                          aria-label={`Translation for ${key} in ${locale}`}
                          id={`translation-${key}`}
                          type="text"
                          value={translations[locale]?.[key] || ""}
                          onChange={(e) =>
                            updateTranslation(locale, key, e.target.value)
                          }
                        />
                      ) : null}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td aria-label="empty" />

                {data.shopLocales.map(({ locale, primary }) => (
                  <td key={locale}>
                    {primary ? (
                      <>
                        <Button onClick={updateTranslationMetafield}>
                          Save {localeMap.get(locale) || locale}
                        </Button>{" "}
                      </>
                    ) : (
                      <>
                        {translations[locale] ? (
                          <>
                            <Button onClick={() => saveTranslation(locale)}>
                              Save {localeMap.get(locale) || locale}
                            </Button>{" "}
                            <Button onClick={() => destroyTranslation(locale)}>
                              Remove {localeMap.get(locale) || locale}
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button onClick={() => saveTranslation(locale)}>
                              Create {localeMap.get(locale) || locale}
                            </Button>{" "}
                          </>
                        )}
                      </>
                    )}
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
          <button
            className="btn btn-danger"
            onClick={destroyTranslationMetafield}
            type="button"
          >
            Use Default Translations
          </button>{" "}
          Removes translation data for the product page buttons
        </>
      ) : (
        <>
          <Button onClick={createTranslationMetafield}>
            Use Custom Translations
          </Button>
        </>
      )}
    </>
  ) : (
    "Loading..."
  );
}

export default WidgetTranslation;
