/* eslint-disable import/no-unresolved */
/* we are doing something hella gross */ /* eslint-disable import/no-webpack-loader-syntax */

import React, { useEffect } from "react";

import legacyJQueryUI from "!!raw-loader!../legacy/css/css/jquery-ui.css";
import legacyBootstrap from "!!raw-loader!../legacy/css/css/bootstrap.css";
import legacyBootstrapResponsive from "!!raw-loader!../legacy/css/css/bootstrap-responsive.css";
import legacyCssOverrides from "!!raw-loader!../legacy/css/css/css_overrides.css";
import legacyCatalystStyle from "!!raw-loader!../legacy/css/css/catalyst-style.css";
import legacyChosen from "!!raw-loader!../legacy/css/css/chosen.min.css";
import legacyStylesheet from "!!raw-loader!../legacy/css/css/stylesheet.css";

const styles = [
  legacyJQueryUI,
  legacyBootstrap,
  legacyBootstrapResponsive,
  legacyCssOverrides,
  legacyCatalystStyle,
  legacyChosen,
  legacyStylesheet,
];

const CONTAINER_BASE_CSS = {
  margin: 0,
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontSize: "14px",
  lineHeight: "20px",
  color: "#333333",
  backgroundColor: "#ffffff",
  fontWeight: 400,
  textTransform: "initial",
  letterSpacing: "initial",
};

function StyleIsolation({ children }) {
  useEffect(() => {
    const styleElements = styles.map((styleContent) => {
      const style = document.createElement("style");
      style.textContent = styleContent;
      return document.head.appendChild(style);
    });

    const linkElement = document.createElement("link");
    linkElement.rel = "stylesheet";
    linkElement.href =
      "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400|Open+Sans:300,400";

    document.head.appendChild(linkElement);
    return () => {
      linkElement.remove();
      styleElements.forEach((styleElement) => styleElement.remove());
    };
  }, []);

  return <div style={CONTAINER_BASE_CSS}>{children}</div>;
}

export default StyleIsolation;
