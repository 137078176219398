import React, { useEffect } from "react";

import { useDisplayRoutes } from "../RouteManager";
import { useRedirect } from "../utils";

function AppBridgeNavigation() {
  const displayRoutes = useDisplayRoutes();
  const redirect = useRedirect();

  useEffect(() => {
    document.title = "";
  }, []);

  return (
    <ui-title-bar>
      {displayRoutes.map(({ name, path, items = [] }) =>
        items.length > 0 ? (
          <section label={name} key={name}>
            {items.map(({ name: subName, path: subPath }) => (
              <button
                type="button"
                onClick={() => redirect(subPath)}
                key={subPath}
              >
                {subName}
              </button>
            ))}
          </section>
        ) : (
          <button type="button" onClick={() => redirect(path)} key={name}>
            {name}
          </button>
        )
      )}
    </ui-title-bar>
  );
}

export default AppBridgeNavigation;
