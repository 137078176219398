import React, { useState, useCallback, useEffect } from "react";
import StyleIsolation from "../components/StyleIsolation";
import LegacyContainer, {
  useGiftReggieFetch,
} from "../components/LegacyContainer";
import LegacyMessageContext from "../components/LegacyMessageContext";

function SMTPSettings() {
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const [smtpHost, setSmtpHost] = useState("");
  const [smtpPort, setSmtpPort] = useState("");
  const [smtpFrom, setSmtpFrom] = useState("");
  const [smtpUser, setSmtpUser] = useState("");
  const [smtpPass, setSmtpPass] = useState("");

  const [saslCheck, setSaslCheck] = useState(false);

  const smtpHostChange = useCallback((e) => setSmtpHost(e.target.value), []);
  const smtpPortChange = useCallback((e) => setSmtpPort(e.target.value), []);
  const smtpFromChange = useCallback((e) => setSmtpFrom(e.target.value), []);
  const smtpUserChange = useCallback((e) => setSmtpUser(e.target.value), []);
  const smtpPassChange = useCallback((e) => setSmtpPass(e.target.value), []);

  const saslCheckChange = useCallback(
    (e) => setSaslCheck(e.target.checked),
    []
  );

  const fetch = useGiftReggieFetch();

  useEffect(() => {
    if (!loaded) {
      const url = new URL(`/reggie/smtp`, window.location);
      // if (email) {
      //   url.searchParams.append("email", email);
      // }
      // url.searchParams.append("items", items || 50);
      // url.searchParams.append("page", page || 1);
      // if (dir && order) {
      //   url.searchParams.append("dir", dir);
      //   url.searchParams.append("order", order);
      // }
      fetch(url, { headers: { Accept: "application/json" } })
        .then((nextData) => {
          setData(nextData);
          setLoaded(true);

          setSmtpHost(nextData.SMTP_HOST);
          setSmtpPort(nextData.SMTP_PORT);
          setSmtpFrom(nextData.SMTP_FROM);
          setSmtpUser(nextData.SMTP_USER);
          setSmtpPass(nextData.SMTP_PASS);

          setSaslCheck(!!nextData.SMTP_USER);
        })
        .catch((ex) => {
          console.error("Fetching Dashboard Data", ex);
        });
    }
  }, [fetch, loaded]);

  const submit = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new URLSearchParams(new FormData(e.target));
      const submitButton = e.nativeEvent.submitter;
      if (submitButton && submitButton.name) {
        formData.append(submitButton.name, submitButton.value || "");
      }
      setData(null);
      fetch(`/reggie/smtp`, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      })
        .then((nextData) => {
          setData(nextData);
        })
        .catch((ex) => {
          console.error("Fetching Dashboard Data after Submit", ex);
        })
        .finally(() => {
          setLoaded(true);
        });
    },
    [fetch]
  );

  const discard = useCallback(() => {
    setLoaded(false);
    setData(null);
  }, []);

  return data ? (
    <StyleIsolation>
      <LegacyContainer>
        <div className="row-fluid">
          <div className="span12">
            <h3>SMTP Configuration</h3>
            <p>
              <b>
                Most users will not need to touch this section. If you do not
                know what an SMTP server is, do not touch any of these settings.
              </b>
            </p>
            <p>
              By default, Gift Reggie will send emails using a service called{" "}
              <a
                target="_blank"
                href="https://postmarkapp.com/"
                rel="noreferrer"
              >
                Postmark
              </a>{" "}
              to deliver emails, and they will all be from the same
              .moddapps.com address. If you&apos;d like to send emails via some
              other address, you can configure this app for use with an SMTP
              server. If the configuration is incorrect, or if there is any
              reason why delivery to the SMTP server fails, the system will
              revert back to using the old postmark address.
            </p>
            <p>
              Once configured, you can automatically send a test email, to make
              sure that everything&apos;s working. Please only use this feature
              if you&apos;re sure about what you&apos;re doing.
            </p>
          </div>
        </div>
        <form action="?" id="smtp-form" method="post" onSubmit={submit}>
          <div className="row-fluid">
            <div className="span4">SMTP Hostname</div>
            <div className="span4">
              <input
                type="text"
                id="smtp-host"
                name="smtp-host"
                placeholder="smtp.example.com"
                value={smtpHost || ""}
                onChange={smtpHostChange}
                required={!!(smtpHost || smtpPort || smtpFrom)}
              />
            </div>
          </div>
          <div className="row-fluid">
            <div className="span4">SMTP Port</div>
            <div className="span4">
              <input
                type="text"
                id="smtp-port"
                name="smtp-port"
                placeholder="587"
                value={smtpPort || ""}
                onChange={smtpPortChange}
                pattern="[1-9]\d{0,3}|[2345]\d\d\d\d|6([0-4]\d\d\d|5([0-4]\d\d|5([0-2]\d|3[0-5])))"
                required={!!(smtpHost || smtpPort || smtpFrom)}
              />
            </div>
          </div>
          <div className="row-fluid">
            <div className="span4">SMTP From</div>
            <div className="span4">
              <input
                type="email"
                id="smtp-from"
                name="smtp-from"
                placeholder="sender@example.com"
                value={smtpFrom || ""}
                onChange={smtpFromChange}
                required={!!(smtpHost || smtpPort || smtpFrom)}
              />
            </div>
          </div>
          <div className="row-fluid">
            <div className="span4">SMTP SSL</div>
            <div className="span4">
              <select
                name="smtp-ssl"
                defaultValue={
                  (data.SMTP_SSL &&
                    (data.SMTP_SSL_STARTTLS ? "starttls" : "1")) ||
                  "0"
                }
              >
                <option value="0">Off</option>
                <option value="starttls">Start TLS</option>
                <option value="1">On Connect</option>
              </select>
            </div>
          </div>
          <div className="row-fluid">
            <div className="span4">SASL Authentication</div>
            <div className="span4">
              <input
                id="sasl-check"
                style={{ width: "auto" }}
                type="checkbox"
                checked={saslCheck}
                onChange={saslCheckChange}
              />
            </div>
          </div>
          <div className="row-fluid">
            <div className="span4">SMTP Username</div>
            <div className="span4">
              <input
                type="text"
                id="smtp-user"
                name="smtp-user"
                placeholder="Username"
                disabled={!saslCheck}
                value={saslCheck ? smtpUser : ""}
                onChange={smtpUserChange}
                required={!!(smtpUser || smtpPass) && saslCheck}
              />
            </div>
          </div>
          <div className="row-fluid">
            <div className="span4">SMTP Password</div>
            <div className="span4">
              <input
                type="password"
                id="smtp-pass"
                name="smtp-pass"
                placeholder="Password"
                disabled={!saslCheck}
                value={saslCheck ? smtpPass : ""}
                onChange={smtpPassChange}
                required={!!(smtpUser || smtpPass) && saslCheck}
              />
            </div>
          </div>
          <div className="row-fluid">
            <div className="span4">SMTP Batch Limit</div>
            <div className="span4">
              <input
                type="text"
                id="smtp-batch-limit"
                name="smtp-max-batch"
                placeholder="50"
                defaultValue={data.SMTP_BATCH_LIMIT}
              />
            </div>
          </div>

          <div className="row-fluid">
            <div className="span12">
              <button
                type="button"
                className="btn-large btn-danger"
                onClick={discard}
              >
                Discard Changes
              </button>{" "}
              <button
                type="submit"
                className="btn-large btn-success"
                name="action"
                value="save-settings"
              >
                Save Settings
              </button>
            </div>
          </div>
          {data.SMTP_HOST ? (
            <>
              <h3>Test Email</h3>
              <p>
                Below, you can send a test email to the specified email address,
                using the above SMTP configuration.
              </p>
              <div className="row-fluid">
                <div className="span4">
                  <input
                    type="email"
                    name="smtp-test-email"
                    placeholder="myemail@example.com"
                  />
                </div>
                <div className="span4">
                  <button
                    type="submit"
                    name="action"
                    value="test-email"
                    className="btn btn-info"
                  >
                    Send Test Email
                  </button>
                </div>
              </div>
            </>
          ) : null}
        </form>
      </LegacyContainer>
    </StyleIsolation>
  ) : null;
}

export default () => (
  <LegacyMessageContext>
    <SMTPSettings />
  </LegacyMessageContext>
);
