import React from "react";

import { Page, Layout, Card } from "@shopify/polaris";
import GroupBuyList from "./components/GroupBuyList";

export const GroupBuyPage = () => (
  <Page fullWidth>
    <Layout>
      <Layout.Section>
        <Card
          title="Group Buy"
          // actions={[
          //   {
          //     content: "create",
          //     url: "/group_buy/create",
          //   },
          // ]}
        >
          <GroupBuyList />
        </Card>
      </Layout.Section>
    </Layout>
  </Page>
);

export default GroupBuyPage;
