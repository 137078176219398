import React, { useState, useEffect, useCallback } from "react";
import {
  ResourceList,
  ResourceItem,
  Text,
  Button,
  BlockStack,
  Icon,
  Tooltip,
  InlineStack,
} from "@shopify/polaris";

import { InfoIcon } from "@shopify/polaris-icons";

import { useFetch } from "../utils";

const GroupBuyList = () => {
  const authFetch = useFetch();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () =>
      authFetch("/api/group_buy").then(async (r) => r.json());

    if (loading) {
      getData()
        .then((jsonResponse) => {
          if (Array.isArray(jsonResponse)) {
            setList(jsonResponse);
            setError(null);
          } else {
            setList([]);
            setError(jsonResponse?.error);
          }
        })
        .finally(() => {
          setLoading(!loading);
        });
    }
  }, [loading, setLoading, authFetch, setError]);

  const Reloadlist = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const resourceListRenderItem = (item, id) => (
    <ResourceItem id={`gb_${id}`} url={`/group_buy/${id}`}>
      <BlockStack gap="400">
        <InlineStack gap="400">
          {item?.customer ? (
            <Text
              as="span"
              fontWeight="semibold"
            >{`${item.customer.first_name} ${item.customer.last_name}`}</Text>
          ) : (
            <Text as="span" fontWeight="semibold">
              Unknown
            </Text>
          )}
          <Text as="span" color="subdued">
            id: {id}
          </Text>
          <Tooltip content={<pre>{JSON.stringify(item, null, 2)}</pre>}>
            <Icon source={InfoIcon} />
          </Tooltip>
        </InlineStack>
        <BlockStack gap="400">
          {item?.products &&
            item.products.map((product) => (
              <Text
                as="span"
                color="subdued"
              >{`${product.name} x ${product.quantity}`}</Text>
            ))}
          {item?.draft_order && (
            <Text
              as="span"
              color="subdued"
            >{`Total: ${item.draft_order.totalPrice}`}</Text>
          )}
        </BlockStack>
        {item?.contributions && (
          <Text
            as="span"
            color="subdued"
          >{`contributions: ${item.contributions.length}`}</Text>
        )}
      </BlockStack>
    </ResourceItem>
  );

  return (
    <>
      <ResourceList
        items={list}
        loading={loading}
        filterControl={
          <InlineStack gap="200" align="end">
            <Button plain fullWidth loading={loading}>
              Create
            </Button>
            <Button plain fullWidth loading={loading} onClick={Reloadlist}>
              Reload
            </Button>
          </InlineStack>
        }
        emptyState={
          <>
            {error && (
              <Text as="span" color="critical">
                {error}
              </Text>
            )}
            {!error && (
              <Text as="span">
                There are no any group buys at the moment.Try informing your new
                customers about this cool new feature they have access to.
              </Text>
            )}
          </>
        }
        renderItem={resourceListRenderItem}
      />
    </>
  );
};

export default GroupBuyList;
