import React, { useCallback, useState, useEffect } from "react";
import {
  AppProvider as PolarisAppProvider,
  Frame,
  Navigation,
  TopBar,
} from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import { SettingsIcon } from "@shopify/polaris-icons";
import { useLocation } from "react-router-dom";
// import { useFetch, useProfile, useIsAuthenticated } from "./utils";
import OverlayManager from "./components/OverlayManager";
import TrialBanner from "./components/TrialBanner";
import { SubscriptionProvider } from "./components/SubscriptionContext";
import zendesk from "./zendesk";
import { OpenReplayContextProvider } from "./components/OpenReplayContext";
import {
  useProfile,
  useFetch,
  useRedirect,
  useIsAuthenticated,
  Link,
} from "./utils";

import { useDisplayRoutes } from "./RouteManager";

function ZendeskProvider() {
  const [profile, profileError] = useProfile();
  const authFetch = useFetch();
  const redirect = useRedirect();

  useEffect(() => {
    if (profile) {
      if (process.env.REACT_APP_ZENAME) {
        if (profile && !profile.user.watchtower) {
          zendesk({
            appNameLong: process.env.REACT_APP_ZENAME,
            userProfile: profile,
            authFetch,
          });
        }
      }
    }
  }, [authFetch, profile, profileError, redirect]);

  return null;
}

function AppFrame({ embedded, children }) {
  const location = useLocation();
  const displayRoutes = useDisplayRoutes();
  const isAuthenticated = useIsAuthenticated();
  const [profile] = useProfile();

  const [userMenuActive, setUserMenuActive] = useState(true);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const dismissMobileNavigation = useCallback(
    () => setMobileNavigationActive(false),
    []
  );

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((active) => !active),
    []
  );

  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive((active) => !active),
    []
  );

  if (!profile && isAuthenticated) {
    return null;
  }

  const fullName =
    profile && profile.user && typeof profile.user === "object"
      ? `${profile.user.first_name} ${profile.user.last_name}`
      : "Anonymous User";
  const initials =
    profile && profile.user && typeof profile.user === "object"
      ? `${String(profile.user.first_name)[0]}${
          String(profile.user.last_name)[0]
        }`
      : "A";

  return (
    <>
      <PolarisAppProvider
        i18n={enTranslations}
        linkComponent={Link}
      >
        <OpenReplayContextProvider>
          <Frame
            topBar={
              embedded ? null : (
                <TopBar
                  showNavigationToggle
                  userMenu={
                    isAuthenticated && (
                      <TopBar.UserMenu
                        actions={[]}
                        name={fullName}
                        detail={profile.shop.shop_origin}
                        initials={initials}
                        open={userMenuActive}
                        onToggle={toggleUserMenuActive}
                      />
                    )
                  }
                  /* searchResultsVisible={searchActive} */
                  /* searchField={<TopBar.SearchField onChange={handleSearchFieldChange} value={searchValue} placeholder="Search" />} */
                  /* searchResults={<ActionList items={[{ content: "Shopify help center" }, { content: "Community forums" }]} />} */
                  /* onSearchResultsDismiss={handleSearchResultsDismiss} */
                  onNavigationToggle={toggleMobileNavigationActive}
                />
              )
            }
            // This is for NON embedded views.
            navigation={
              embedded ? null : (
                <Navigation location={location.pathname}>
                  <Navigation.Section
                    items={displayRoutes.map(({ name, path, items = [] }) => ({
                      url: path,
                      label: name,
                      icon: SettingsIcon,
                      subNavigationItems: items.map(
                        ({ name: subName, path: subPath }) => ({
                          url: subPath,
                          label: subName,
                        })
                      ),
                    }))}
                  />
                </Navigation>
              )
            }
            showMobileNavigation={mobileNavigationActive}
            onNavigationDismiss={dismissMobileNavigation}
          >
            <OverlayManager>
              <SubscriptionProvider>
                <div
                  style={{
                    overflow: "hidden",
                    maxWidth: "calc(100% - 0.2rem)",
                  }}
                >
                  <TrialBanner />
                </div>
                {children}
              </SubscriptionProvider>
            </OverlayManager>
          </Frame>
          <ZendeskProvider />
        </OpenReplayContextProvider>
      </PolarisAppProvider>
    </>
  );
}

export default AppFrame;
