import React, { useEffect, useState } from "react";
import { Card, DataTable, Page, Layout } from "@shopify/polaris";
import { useFetch, Link } from "../utils";
import LegacyMessageContext from "../components/LegacyMessageContext";
import UsageChargeWidget from "../components/UsageChargeWidget";
import CreatePagination from "./components/CreatePagination";

function UsageBilling() {
  const authFetch = useFetch();
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(null);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!loaded) {
      const url = new URL("/reggie/usage", window.location);

      url.searchParams.set("page", page);

      // fetch from roro
      authFetch(url, { headers: { Accept: "application/json" } })
        .then((r) => r.json())
        .then((response) => {
          setData(response);
          setLoaded(true);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(`Error getting page data. ${e}`);
        });
    }
  }, [authFetch, loaded, page]);

  return data ? (
    <Page fullWidth title="Usage Billing">
      <Layout>
        {data.GRANDFATHERED ? (
          <Layout.Section fullWidth>
            <h4 style={{ color: "red" }}>
              You have been grandfathered into Gift Reggie&apos;s non-usage
              based billing platform. So long as you stay on your current
              subscription, you will not be billed based on usage, though you
              may be asked to migrate to a new plan at some point in the future.
            </h4>
          </Layout.Section>
        ) : (
          <>
            <Layout.Section fullWidth>
              <Card title="Usage-Based Bills">
                <DataTable
                  columnContentTypes={[
                    "text",
                    "text",
                    "numeric",
                    "numeric",
                    "numeric",
                    "numeric",
                  ]}
                  headings={[
                    "Month",
                    "Plan Type",
                    "Amount of Active Registries",
                    "Free Registries",
                    "Amount of Charged Registries",
                    "Total Charge",
                  ]}
                  rows={
                    ([
                      <i>{data.FUTURE_MONTH}</i>,
                      <i>{data.PLAN_NAME}</i>,
                      <i>
                        <Link to="/registries">
                          {data.ACTIVE_REGISTRY_COUNT}
                        </Link>
                      </i>,
                      <i>{data.FREE_REGISTRIES}</i>,
                      <i>{data.FUTURE_CHARGED_REGISTRIES}</i>,
                      <i>$ {data.FUTURE_CHARGED_AMOUNT}</i>,
                    ],
                    data.CHARGES.map((innerData) => [
                      <i>{innerData.MONTH}</i>,
                      <i>{innerData.PLAN}</i>,
                      <i>{innerData.ACTIVE_REGISTRY_COUNT} </i>,
                      <i>{innerData.FREE_REGISTRIES}</i>,
                      <i>{innerData.CHARGED_REGISTRIES}</i>,
                      <i>$ {innerData.CHARGED_AMOUNT}</i>,
                    ]))
                  }
                />
              </Card>

              <CreatePagination
                maxPages={data.MAX_PAGES}
                page={page}
                setPage={setPage}
              />
            </Layout.Section>
          </>
        )}
        <Layout.Section fullWidth>
          <UsageChargeWidget data={data} />
        </Layout.Section>
      </Layout>
    </Page>
  ) : null;
}
export default () => (
  <LegacyMessageContext>
    <UsageBilling />
  </LegacyMessageContext>
);
