/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { useFetch, useProfile, useRedirect, Link } from "../utils";
import LegacyContainer, {
  useGiftReggieFetch,
} from "../components/LegacyContainer";
import StyleIsolation from "../components/StyleIsolation";
import LegacyMessageContext from "../components/LegacyMessageContext";
import WidgetTranslation from "./components/WidgetTranslation";

const LANGUAGES = [
  ["en", "English"],
  ["fr", "French"],
  ["es", "Spanish"],
  ["pt", "Portuguese"],
  ["de", "German"],
  ["ru", "Russian"],
  ["ja", "Japanese"],
  ["zh", "Chinese"],
  ["", "\u00a0"],
  ["ab", "Abkhaz"],
  ["aa", "Afar"],
  ["af", "Afrikaans"],
  ["ak", "Akan"],
  ["sq", "Albanian"],
  ["am", "Amharic"],
  ["ar", "Arabic"],
  ["an", "Aragonese"],
  ["hy", "Armenian"],
  ["as", "Assamese"],
  ["av", "Avaric"],
  ["ae", "Avestan"],
  ["ay", "Aymara"],
  ["az", "Azerbaijani"],
  ["bm", "Bambara"],
  ["ba", "Bashkir"],
  ["eu", "Basque"],
  ["be", "Belarusian"],
  ["bn", "Bengali, Bangla"],
  ["bh", "Bihari"],
  ["bi", "Bislama"],
  ["bs", "Bosnian"],
  ["br", "Breton"],
  ["bg", "Bulgarian"],
  ["my", "Burmese"],
  ["ca", "Catalan"],
  ["ch", "Chamorro"],
  ["ce", "Chechen"],
  ["ny", "Chichewa, Chewa, Nyanja"],
  ["cv", "Chuvash"],
  ["kw", "Cornish"],
  ["co", "Corsican"],
  ["cr", "Cree"],
  ["hr", "Croatian"],
  ["cs", "Czech"],
  ["da", "Danish"],
  ["dv", "Divehi, Dhivehi, Maldivian"],
  ["nl", "Dutch"],
  ["dz", "Dzongkha"],
  ["eo", "Esperanto"],
  ["et", "Estonian"],
  ["ee", "Ewe"],
  ["fo", "Faroese"],
  ["fj", "Fijian"],
  ["fi", "Finnish"],
  ["ff", "Fula, Fulah, Pulaar, Pular"],
  ["gl", "Galician"],
  ["ka", "Georgian"],
  ["el", "Greek (modern)"],
  ["gn", "Guaraní"],
  ["gu", "Gujarati"],
  ["ht", "Haitian, Haitian Creole"],
  ["ha", "Hausa"],
  ["he", "Hebrew (modern)"],
  ["hz", "Herero"],
  ["hi", "Hindi"],
  ["ho", "Hiri Motu"],
  ["hu", "Hungarian"],
  ["ia", "Interlingua"],
  ["id", "Indonesian"],
  ["ie", "Interlingue"],
  ["ga", "Irish"],
  ["ig", "Igbo"],
  ["ik", "Inupiaq"],
  ["io", "Ido"],
  ["is", "Icelandic"],
  ["it", "Italian"],
  ["iu", "Inuktitut"],
  ["jv", "Javanese"],
  ["kl", "Kalaallisut, Greenlandic"],
  ["kn", "Kannada"],
  ["kr", "Kanuri"],
  ["ks", "Kashmiri"],
  ["kk", "Kazakh"],
  ["km", "Khmer"],
  ["ki", "Kikuyu, Gikuyu"],
  ["rw", "Kinyarwanda"],
  ["ky", "Kyrgyz"],
  ["kv", "Komi"],
  ["kg", "Kongo"],
  ["ko", "Korean"],
  ["ku", "Kurdish"],
  ["kj", "Kwanyama, Kuanyama"],
  ["la", "Latin"],
  ["lb", "Luxembourgish, Letzeburgesch"],
  ["lg", "Ganda"],
  ["li", "Limburgish, Limburgan, Limburger"],
  ["ln", "Lingala"],
  ["lo", "Lao"],
  ["lt", "Lithuanian"],
  ["lu", "Luba-Katanga"],
  ["lv", "Latvian"],
  ["gv", "Manx"],
  ["mk", "Macedonian"],
  ["mg", "Malagasy"],
  ["ms", "Malay"],
  ["ml", "Malayalam"],
  ["mt", "Maltese"],
  ["mi", "Māori"],
  ["mr", "Marathi (Marāṭhī)"],
  ["mh", "Marshallese"],
  ["mn", "Mongolian"],
  ["na", "Nauruan"],
  ["nv", "Navajo, Navaho"],
  ["nd", "Northern Ndebele"],
  ["ne", "Nepali"],
  ["ng", "Ndonga"],
  ["nb", "Norwegian Bokmål"],
  ["nn", "Norwegian Nynorsk"],
  ["no", "Norwegian"],
  ["ii", "Nuosu"],
  ["nr", "Southern Ndebele"],
  ["oc", "Occitan"],
  ["oj", "Ojibwe, Ojibwa"],
  ["cu", "Old Church Slavonic, Church Slavonic, Old Bulgarian"],
  ["om", "Oromo"],
  ["or", "Oriya"],
  ["os", "Ossetian, Ossetic"],
  ["pa", "Panjabi, Punjabi"],
  ["pi", "Pāli"],
  ["fa", "Persia, (Farsi)"],
  ["pl", "Polish"],
  ["ps", "Pashto, Pushto"],
  ["qu", "Quechua"],
  ["rm", "Romansh"],
  ["rn", "Kirundi"],
  ["ro", "Romanian"],
  ["sa", "Sanskrit (Saṁskṛta)"],
  ["sc", "Sardinian"],
  ["sd", "Sindhi"],
  ["se", "Northern Sami"],
  ["sm", "Samoan"],
  ["sg", "Sango"],
  ["sr", "Serbian"],
  ["gd", "Scottish Gaelic, Gaelic"],
  ["sn", "Shona"],
  ["si", "Sinhala, Sinhalese"],
  ["sk", "Slovak"],
  ["sl", "Slovene"],
  ["so", "Somali"],
  ["st", "Southern Sotho"],
  ["su", "Sundanese"],
  ["sw", "Swahili"],
  ["ss", "Swati"],
  ["sv", "Swedish"],
  ["ta", "Tamil"],
  ["te", "Telugu"],
  ["tg", "Tajik"],
  ["th", "Thai"],
  ["ti", "Tigrinya"],
  ["bo", "Tibetan Standard, Tibetan, Central"],
  ["tk", "Turkmen"],
  ["tl", "Tagalog"],
  ["tn", "Tswana"],
  ["to", "Tonga (Tonga Islands)"],
  ["tr", "Turkish"],
  ["ts", "Tsonga"],
  ["tt", "Tatar"],
  ["tw", "Twi"],
  ["ty", "Tahitian"],
  ["ug", "Uyghur"],
  ["uk", "Ukrainian"],
  ["ur", "Urdu"],
  ["uz", "Uzbek"],
  ["ve", "Venda"],
  ["vi", "Vietnamese"],
  ["vo", "Volapük"],
  ["wa", "Walloon"],
  ["cy", "Welsh"],
  ["wo", "Wolof"],
  ["fy", "Western Frisian"],
  ["xh", "Xhosa"],
  ["yi", "Yiddish"],
  ["yo", "Yoruba"],
  ["za", "Zhuang, Chuang"],
  ["zu", "Zulu"],
];

const localeMap = new Map(LANGUAGES);

function flatten(obj, prefix = "") {
  return Object.fromEntries(
    Object.entries(obj).flatMap(([key, value]) =>
      value instanceof Object
        ? Object.entries(flatten(value, `${prefix}${key}.`))
        : [[`${prefix}${key}`, value]]
    )
  );
}

function PresenceDetector({ updateForm, selectedLocale }) {
  const elementRef = useRef(null);
  useEffect(() => {
    updateForm(elementRef.current.closest("form"), selectedLocale);
  }, [selectedLocale, updateForm]);
  return <div hidden ref={elementRef} />;
}

function Translations() {
  const [data, setData] = useState(null);
  const [loadedId, setLoadedId] = useState(false);

  const [selectedLocale, setSelectedLocale] = useState("en");

  const translations = useMemo(
    () =>
      Object.fromEntries(
        data?.PRESETS?.map?.(({ TAG, PRESET }) => {
          try {
            return [TAG, flatten(JSON.parse(PRESET))];
          } catch {
            return [TAG, {}];
          }
        }) ?? []
      ),
    [data?.PRESETS]
  );

  const updateForm = useCallback(
    (formElement, newSelectedLocale) => {
      formElement
        .querySelectorAll("[data-locale-property-key]")
        .forEach((e) => {
          const propertyKey = e.dataset.localePropertyKey;
          e.value = translations[newSelectedLocale]?.[propertyKey] || "";
        });
    },
    [translations]
  );

  const { id } = useParams();
  const redirect = useRedirect();

  const giftReggieFetch = useGiftReggieFetch();
  const authFetch = useFetch();

  const [
    {
      shop: { shop_origin: shopOrigin },
    },
  ] = useProfile();

  const updateSelectedLocale = useCallback(
    (event) => {
      const newSelectedLocale = event.target.value;
      const formElement = event.target.form;

      setSelectedLocale(newSelectedLocale);
      if (!id) {
        // only populate defaults if this is a new translation
        updateForm(formElement, newSelectedLocale);
      }
    },
    [id, updateForm]
  );

  const profile = useProfile()[0];
  const scopes = String(profile?.shop?.scope).split(/,\s*/);
  const hasTranslationPermission =
    scopes.includes("write_translations") && scopes.includes("read_locales");

  useEffect(() => {
    if (loadedId !== id) {
      const url = new URL(
        id ? `/reggie/translations/${id}` : "/reggie/translations",
        window.location
      );

      giftReggieFetch(url, { headers: { Accept: "application/json" } })
        .then((nextData) => {
          setData(nextData);
          setLoadedId(id);
        })
        .catch((ex) => {
          console.error("Fetching Data", ex);
        });
    }
  }, [giftReggieFetch, id, loadedId]);

  useEffect(() => {
    const firstUnusedLocale =
      LANGUAGES.find(([code]) =>
        data?.LOCALES?.every?.(({ TAG }) => TAG !== code)
      )?.[0] || "en";

    setSelectedLocale(data?.ACTIVE_LOCALE_TAG || firstUnusedLocale);
  }, [data, id, loadedId, updateForm]);

  const submit = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new URLSearchParams(new FormData(e.target));
      const submitButton = e.nativeEvent.submitter;
      if (submitButton && submitButton.name) {
        formData.append(submitButton.name, submitButton.value || "");
      }
      setData(null);
      giftReggieFetch(
        id ? `/reggie/translations/${id}` : "/reggie/translations",
        {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        }
      )
        .finally(() => {
          setLoadedId(null);
        })
        .catch((ex) => {
          console.error("Fetching Data after Submit", ex);
        });
    },
    [giftReggieFetch, id]
  );

  const languageOptions = useMemo(
    () =>
      data?.LOCALES
        ? LANGUAGES.map(([code, name]) => (
            <option
              key={code}
              value={code}
              disabled={
                code === "" ||
                data.LOCALES.some(
                  ({ TAG }) => TAG === code && data.ACTIVE_LOCALE_TAG !== TAG
                )
              }
            >
              {name}
            </option>
          ))
        : null,
    [data?.LOCALES, data?.ACTIVE_LOCALE_TAG]
  );

  return data && loadedId === id ? (
    <StyleIsolation>
      <LegacyContainer>
        <h3>Translations</h3>

        <p>
          Gift Reggie has full multi-language support, using Shopify&apos;s
          native translation interface. Below, you can specify a list of
          translations for all the locales present on your active theme. The
          locale with a * next to its name is marked as the default.
        </p>

        <p>
          To trigger a translation, have the user navigate to a Gift Reggie
          address, with ?lang=TAG in the address bar. Translation resolution
          rules are as follows:
        </p>

        <ol>
          <li>
            If your shop has no translations, the default English is used.
          </li>
          <li>
            If your shop has no default translations, and there is no tag in the
            address bar, or the tag used does not match a translation you have,
            the default English is used.
          </li>
          <li>
            If your shop has a default translation, and there is no tag in the
            address bar, or the tag used does not match a translation you have,
            the translation marked as default is used.
          </li>
          <li>
            If there is a tag in the address bar, and that translation exists on
            your store, that translation is used.
          </li>
        </ol>

        <p>
          If you use langify, please see our{" "}
          <a target="_blank" href="about:blank" rel="noreferrer">
            integrations
          </a>{" "}
          section for a snippet which allows you to integrate Gift Reggie with
          Langify.
        </p>

        <style>
          {`
          .translations input[type='text'] {
            margin: 0;
            margin-bottom: 4px;
            width: 100%;
          }

          #locale-selector {
            margin-bottom: 4px;
          }

          .section {
            border-top: 1px solid #AAA;
          }

          .section .span3 {
            padding-top: 4px;
          }

          .subsection .span3 {
            padding-left: 10px;
          }

          .subsection {
            margin-bottom: 12px;
          }

          .subsection h5 {
            font-weight: bold;
          }
          `}
        </style>

        <hr />

        <div className="row-fluid">
          <div className="span12">
            <ul className="nav nav-tabs">
              {data.LOCALES.map((localeData) => (
                <li
                  key={localeData.ID}
                  className={localeData.ACTIVE ? "active" : ""}
                >
                  <Link to={`/translations/${localeData.ID}`}>
                    {localeData.NAME}
                    {localeData.DEFAULT ? " *" : null}
                  </Link>
                </li>
              ))}
              <li className={data.ACTIVE_LOCALE_ID ? "" : "active"}>
                <Link to="/translations">New Template</Link>
              </li>
            </ul>
          </div>
        </div>

        <form
          id="translations-form"
          method="post"
          onSubmit={submit}
          key={id ?? "new"}
        >
          {/*
            some wonkyness with style isolation means that refs don't get updated when you expect them to
            a local PresenceDetector is used to know when the form gets contructed so we can populate default values
           */}
          {id ? null : (
            <PresenceDetector
              updateForm={updateForm}
              selectedLocale={selectedLocale}
            />
          )}
          <input type="hidden" name="myshopify_domain" value={shopOrigin} />
          <div className="row-fluid">
            <div className="span3">
              <h4>Name</h4>
            </div>
            <div className="span9">
              <select
                id="language-tag"
                name="tag"
                required
                onChange={updateSelectedLocale}
                value={selectedLocale}
              >
                {languageOptions}
              </select>
            </div>
          </div>
          <div className="row-fluid">
            <div className="span3">
              <h4>Tag</h4>
            </div>
            <div className="span9" id="tag-display">
              <span style={{ top: "6px", position: "relative" }}>
                {selectedLocale}
              </span>
            </div>
          </div>
          <div className="row-fluid">
            <div className="span3">
              <h4>Default</h4>
            </div>
            <div className="span9">
              <input
                type="checkbox"
                name="default"
                defaultChecked={data.ACTIVE_LOCALE_DEFAULT}
              />
            </div>
          </div>
          <div className="row-fluid">
            <div className="span12">
              <div className="translations">
                {data.SECTIONS.map((sectionData) => (
                  <div className="section" key={sectionData.NAME}>
                    <div className="row-fluid">
                      <h4>{sectionData.NAME}</h4>
                      {sectionData.PROPERTIES.map((propertyData) =>
                        propertyData.IS_HASH ? (
                          <div className="subsection" key={propertyData.ID}>
                            <h5>{propertyData.NAME}</h5>
                            {propertyData.SUBSECTIONS.map((subsectionData) => (
                              <div
                                className="row-fluid"
                                key={subsectionData.ID}
                              >
                                <div className="span3">
                                  {subsectionData.NAME}
                                </div>
                                <div className="span9">
                                  <input
                                    type="text"
                                    name={`locale.${subsectionData.ID}`}
                                    defaultValue={
                                      data.ACTIVE_LOCALE_TAG
                                        ? subsectionData.VALUE
                                        : translations[selectedLocale]?.[
                                            subsectionData.ID
                                          ] || ""
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="row-fluid" key={propertyData.ID}>
                            <div className="span3">{propertyData.NAME}</div>
                            <div className="span9">
                              <input
                                type="text"
                                name={`locale.${propertyData.ID}`}
                                data-locale-property-key={propertyData.ID}
                                defaultValue={propertyData.VALUE}
                              />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <button
                type="submit"
                className="btn btn-large btn-success"
                name="action"
                value="save"
              >
                {id ? "Save Translation" : "Create Translation"}
              </button>{" "}
              {id && (
                <button
                  type="button"
                  className="btn btn-large btn-danger"
                  onClick={() => {
                    if (
                      confirm(
                        `Are you sure you want to delete the ${data.ACTIVE_LOCALE_NAME} translation?`
                      )
                    ) {
                      setData(null);
                      authFetch(`/reggie/translations/${id}`, {
                        method: "POST",
                        body: new URLSearchParams([
                          ["myshopify_domain", "eshopsam.myshopify.com"],
                          ["action", "delete"],
                        ]),
                      }).finally(() => {
                        setLoadedId(null);
                        redirect("/translations/");
                      });
                    }
                    return false;
                  }}
                >
                  Delete Translation
                </button>
              )}
            </div>
          </div>
        </form>
        <hr />
        {hasTranslationPermission ? (
          <WidgetTranslation localeMap={localeMap} />
        ) : (
          <>
            <p>
              Access to some translations features has not been granted yet.
            </p>
            <button
              type="button"
              className="btn"
              onClick={async () => {
                await authFetch("/api/profile/scopes/add", {
                  method: "POST",
                  body: new URLSearchParams({ scopes: "write_translations" }),
                });
                await authFetch("/api/profile/scopes/add", {
                  method: "POST",
                  body: new URLSearchParams({ scopes: "read_locales" }),
                });
                authFetch(`/api/login?shop=${shopOrigin}`)
                  .then((r) => r.json())
                  .then((login) => {
                    redirect(login.url);
                  });
              }}
            >
              Grant access to translations
            </button>
          </>
        )}
      </LegacyContainer>
    </StyleIsolation>
  ) : null;
}

export default () => (
  <LegacyMessageContext>
    <Translations />
  </LegacyMessageContext>
);
